import {polyfill} from 'es6-promise';
import "babel-polyfill";
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/ie9';

import React, {Component} from 'react';
import {BrowserRouter, Route, Switch} from "react-router-dom"

import Home from "./pages/Home";
import Header from "./components/header/Header";
import ErrorPage from "./pages/ErrorPage";
import Footer from "./components/footer/Footer";
import Partner from "./pages/Partner";
import Kapcsolat from "./pages/Kapcsolat";
import BlogPost from "./pages/BlogPost";
import Adatkezeles from "./pages/Adatkezeles";
import Karrier from "./pages/Karrier";
import Kolcsonok from "./pages/Kolcsonok";
import Biztositasok from "./pages/Biztositasok";
import Blogs from "./pages/Blogs";
import CalculatorPage from "./pages/CalculatorPage";
import ScrollToTop from "./utils/ScrollToTop";
import {Button} from "@material-ui/core";

import ReactGA from 'react-ga';
import EventSystem from "./utils/EventSystem";
import FBPixel from "./utils/FBPixel";

import "swiper/swiper.min.css";
import "swiper/swiper-bundle.min.css";
import JobPage from "./pages/JobPage";

polyfill();

class App extends Component {

    state = {
        cookie: false,
    };

    componentDidMount() {
        this.setState({
            cookie: window.localStorage.getItem("cookie") === "1",
        });

        ReactGA.initialize('UA-145079465-1');
        EventSystem.subscribe('urlChanged', () => {
            //Google analytics pagerefresh send
            ReactGA.set({page: window.location.pathname}); // Update the user's current page
            ReactGA.pageview(window.location.pathname); // Record a pageview for the given page
            //fb pixel pageview send
            FBPixel.pageView();
        });
        FBPixel.init('485702698653564');
    }

    handleCookieBtn() {
        window.localStorage.setItem("cookie", "1");
        this.setState({
            cookie: true,
        });
    }

    render() {
        console.log(this.props.loc);
        return (
            <div className="App">
                <meta name="viewport" content="width=device-width, maximum-scale=1.0"/>
                <link
                    rel="stylesheet"
                    href="https://maxcdn.bootstrapcdn.com/bootstrap/4.3.1/css/bootstrap.min.css"
                    integrity="sha384-ggOyR0iXCbMQv3Xipma34MD+dH/1fQ784/j6cY/iJTQUOhcWr7x9JvoRxT2MZw1T"
                    crossOrigin="anonymous"
                />
                <div className={'cookies' + (this.state.cookie ? ' hidden' : '')}>
                    Kedves Látogató! Tájékoztatjuk, hogy a honlap felhasználói élmény fokozásának érdekében sütiket
                    alkalmazunk. A honlapunk használatával Ön a tájékoztatásunkat tudomásul veszi. Adatvédelmi
                    tájékoztatás a láblécen keresztül elérhető.
                    <Button onClick={() => this.handleCookieBtn()} className={"button_cookies"}
                            variant={"outlined"}>Elfogadom</Button>
                </div>
                <BrowserRouter>
                    <ScrollToTop>
                        <Header/>
                        <Switch>
                            <Route path={"/"} component={Home} exact/>
                            <Route path={"/blog"} component={Blogs} exact/>
                            <Route path={"/blog/:id"} component={BlogPost} exact/>
                            <Route path={"/job/:id"} component={JobPage} exact/>
                            <Route path={"/adatkezeles"} component={Adatkezeles} exact/>
                            <Route path={"/hitel"} component={Kolcsonok} exact/>
                            <Route path={"/biztositas"} component={Biztositasok} exact/>
                            <Route path={"/karrier"} component={Karrier} exact/>
                            <Route path={"/kapcsolat"} component={Kapcsolat} exact/>
                            {/*<Route path={"/provident"} component={Provident} exact/>*/}
                            <Route path={"/partner"} component={Partner} exact/>
                            <Route path={"/kalkulator"} component={CalculatorPage} exact/>

                            <Route path={"/adossagrendezes"} component={Kolcsonok}/>
                            <Route path={"/lakasbiztositas"} component={Biztositasok}/>
                            <Route path={"/megtakaritasok"} component={Biztositasok}/>
                            <Route path={"/uj-hitel"} component={Kolcsonok}/>
                            <Route component={ErrorPage}/>
                        </Switch>
                        <Footer/>
                    </ScrollToTop>
                </BrowserRouter>
            </div>
        );
    }
}

export default App;
