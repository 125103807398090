import React, {Component} from 'react';
import bg from '../assets/career/credithome_karrier.jpg';
import {p} from "@material-ui/core";
import ContactButton from "../components/contactbutton/ContactButton";
import BlogPostCard from "../components/blog/BlogPostCard";
import AliceCarousel from "react-alice-carousel";
import {NavLink} from "react-router-dom";
import APICaller from "../backend/APICaller";
import karrierVideo from "../assets/Credithome_trukk2_hd1080.mp4";
import telesalesPic from '../assets/telesales.jpg';
import hostessPic from '../assets/host_hostess.jpg';
import styled from 'styled-components';
import CareerBlocks from "../components/carrier/CareerBlocks";
// noinspection NpmUsedModulesInstalled
import {Swiper, SwiperSlide} from 'swiper/react/swiper-react';
import {Autoplay} from "swiper";
import erste from '../assets/career/erste.png';
import cetelem from '../assets/career/cetelem.png';
import otp from '../assets/career/otp.jpg';
import bb from '../assets/career/bb.png';
import rf from '../assets/career/rf.png';
import city from '../assets/career/cit.png';
import ApplyButton from "../components/applyButton/ApplyButton";

const PartnersListWrapper = styled.div`
  margin-top: 24px;
  width: 60%;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  flex-wrap: wrap;
`;

const PartnerBlock = styled.div`
  flex-grow: 2;
  min-width: 200px;
  height: 200px;
  max-width: 200px;

  background-color: #f4f4f4;
  box-shadow: 0 0 6px -1px grey;

  transition: width 300ms ease-in-out, height 300ms ease-in-out, transform 300ms ease-in-out, box-shadow 300ms ease-in-out;

  &:hover {
    transform: scale(1.2);
  }

  & > img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`;

const Jobs = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 90%;
  margin-top: 12px;
`;

const Job = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding: 12px;
  background-color: #efefef;
  margin: 6px 0;
  border-radius: 6px;
  box-shadow: 0 0 6px -1px #3d3d3d;
  font-weight: bold;
`;

const PartnersSection = styled.div`
  width: 100%;
  margin: 12px 0;
  padding: 0 48px;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const HeadLine = styled.div`
  font-family: "Helvetica Neue", sans-serif;
  font-size: 20pt;
  width: 100%;
  text-align: center;
  color: #0f1434;
  font-weight: bold;
  margin: 24px 0;
`;

const BGImg = styled.img`
  width: 100%;
  max-height: 75vh;
  overflow: hidden;
  object-fit: cover;
`;

const blogIDs = [5, 6, 7, 8];

class Karrier extends Component {
  job1 = {height: 500};
  job2 = {height: 500};

  state = {
    blogs: [],
    jobs: [],
    job1H: 500,
    job2H: 500,
    width: window.innerWidth
  };

  styles = {
    video: {
      width: "100%",
    },
    wrapper: {
      position: "relative",
      width: '100%',
      overflow: 'hidden',
    },
    bg1: {
      position: "absolute",
      top: "2.5%",
      width: "100vw",
      height: "100px",
      transform: "skewY(-1.7deg)",
      zIndex: "-2",
      backgroundColor: "#0edea4",
    },
    jobs: {
      display: "flex",
      flexDirection: "column",
      flexWrap: "wrap",
      justifyContent: "flex-start",
      alignItems: "center",
      width: "100%",
    },
    jobSmall: {
      display: "flex",
      flexDirection: "row",
      flexWrap: "wrap",
      justifyContent: "center",
      alignItems: "center",
      width: "100%",
    },
    job: {
      display: "flex",
      flexDirection: "row",
      flexWrap: "nowrap",
      justifyContent: "center",
      alignItems: "center",
      width: "100%",
    },
    job_reverse: {
      display: "flex",
      flexDirection: "row-reverse",
      flexWrap: "nowrap",
      justifyContent: "center",
      alignItems: "center",
      width: "100%",
    },
    jobContent: {
      width: window.innerWidth < 600 ? "100%" : "50%",
      padding: "25px",
    },
    jobImage1: {
      width: window.innerWidth < 600 ? "100%" : "50%",
      objectFit: "cover",
      overflow: "hidden",
      height: this.state.job1H,
    },
    jobImage2: {
      width: window.innerWidth < 600 ? "100%" : "50%",
      objectFit: "cover",
      overflow: "hidden",
      height: this.state.job2H,
    },
  };

  componentDidMount() {
    blogIDs.forEach(blogID => {
      APICaller.getBlog(blogID, (res) => {
        let blogs = this.state.blogs;
        blogs.push(res);
        this.setState({
          blogs,
        });
      });
    });
    this.setState({job1H: this.job1.clientHeight, job2H: this.job2.clientHeight});

    APICaller.getAllJobs(res => {
      let jobs = res;
      this.setState({jobs});
    });

    document.addEventListener('resize', () => this.resizeOccurred());
  }

  resizeOccurred() {
    this.setState({
      width: window.innerWidth
    });
  }

  componentWillUnmount() {
    document.removeEventListener('resize', () => this.resizeOccurred());
  }

  getSlidesPerView() {
    let w = this.state.width;

    if(w >= 1300)
      return 2;
    else if(w < 1300 && w >= 1000)
      return 2;
    else if(w < 1000 && w >= 800)
      return 2;
    else if(w < 800 && w >= 600)
      return 1;
    else if(w < 600)
      return 1;
  }

  render() {
    let duration = 300;
    let wrapBorders = {
      0: {items: 1},
      850: {items: 2},
      1250: {items: 3},
      1700: {items: 4}
    };
    let jobStyle1 = {...this.styles.jobImage1, height: window.innerWidth < 600 ? "100%" : this.state.job1H};
    let jobStyle2 = {...this.styles.jobImage2, height: window.innerWidth < 600 ? "100%" : this.state.job2H};

    return (
      <div className={"page"}
           onResize={() => this.setState({job1H: this.job1.clientHeight, job2H: this.job2.clientHeight})}>
        <div style={this.styles.wrapper}>
          <div className={"wrapper"}>
            <br/>
            <h3>Pályakezdő helyzete Magyarországon</h3>
            <br/>
            <p>
              Míg a magyarországi munkanélküliségi ráta a harmadik legkedvezőbb az Európai Unióban, az
              ifjúsági munkanélküliség még mindig a kétszerese ennek. Ma a vállalatok jelentős része
              munkaerőhiánnyal küszködik, miközben a fiatalok nagyrésze nem találja a helyét a munkaerő
              piacon. A szakértők az alapképzés erősítésében és újfajta, a kommunikációs kompetenciákra
              nagyobb hangsúlyt helyező tréningek elindításában látják a lehetőséget a fiatalok
              foglalkoztatásának javítására.
            </p>
            <p>
              A fiatalok döntő többsége csak az iskola elvégzése után kezd ismerkedni a munkaerőpiaccal,
              keresi a kapcsolatot potenciális munkaadóival. Ekkor még sokan nincsenek tisztában saját
              erényeikkel, érdeklődési köreikkel, és jellemzően nem rendelkeznek azokkal az ismeretekkel,
              képességekkel, amelyek meggyorsíthatnák elhelyezkedésüket, munkahelyi beilleszkedésüket.
            </p>
            <br/>
            <p>
              A pályakezdők közül sokan panaszkodnak arról, hogy a legtöbb és vágyott állásokhoz a szakmai
              tapasztalat általában alapfeltétel. Egy pályakezdő azonban legfeljebb gyakornokként tud
              szert
              tenni szakmai tapasztalatra, amiért a cégek többsége egyáltalán nem, vagy csak igen keveset
              fizet. sokan olyan munkahelyre adják be a jelentkezésüket, amelyhez iskolai végzettségük
              alapján
              túlképzettek, és esetleg szakmai érdeklődésüket illetően másfelé kacsingatnának szívesen. A
              lényeg: a havi fix bevétel.
            </p>
            <br/>
            <h3>Önismeret?</h3>
            <br/>
            <video controls style={this.styles.video}>
              <source src={karrierVideo} type={"video/mp4"}/>
            </video>
            <br/>
            <br/>
            <h5>Mi lehet az oka annak, hogy a mai fiatalok nem tudnak elhelyezkedni?</h5>
            <p>
              <b>Az önismeret, az önbizalom és a kommunikációs képesség hiánya!</b><br/>
              Az önismeret hiánya bizonytalansághoz, szorongáshoz, alul- vagy túlértékelt énképhez,
              kommunikációs problémákhoz, párkapcsolati zavarokhoz, családi, munkahelyi konfliktusokhoz
              vezethet. Az életben minden, ami elgondolkoztat önmagunkon, része az önmagunk
              megismerésének, de
              ebben a folyamatban sajnos sokszor becsapjuk magunkat, hiszen nem tudjuk külső szemmel,
              torzításmentesen látni saját személyiségünket, viselkedésünket.
            </p>
            <br/>
            <p>
              Bátran mondhatom, hogy az önismeret az alapja mindennek az életünkben. Sokat hallani ma az
              önismeretről, elcsépelt ugyanakkor homályos fogalom is. Valami állandónak, statikus
              ismeretnek
              tűnik, amit, ha egyszer megszereztünk, készen vagyunk. Ez persze nem így van. Az
              önmegismerés
              egy életen át tartó folyamat. De az ember gondolatai - szerencsére - nem állandóan önmaga
              körül
              zakatolnak, és az is igaz, hogy időnként módosítani kényszerülünk az önmagunkról kialakított
              képen. Néha azonban érdemes megállni, körülnézni, megvizsgálni a kapcsolatainkat és
              legfőképpen
              önmagunkat.
            </p>
            <br/>
            <p>
              <b>A valódi, reális önismeret segít:</b>
              <ul>
                <li>eligazodni emberi kapcsolatainkban</li>
                <li>egészségesebb, nyugodtabb lelkületet kialakítani magunkban</li>
                <li>változtatni dolgokon</li>
                <li>megerősödve újra és újra szembenézni a kihívásokkal</li>
                <li>Új területeken kipróbálni magunkat</li>
              </ul>
            </p>
            <div className={"centeredWrapper"}>
              <ApplyButton title={"Általános jelentkezés"}/>
            </div>
          </div>
        </div>
        <div className={"wrapper"}>
          <h3>Karrierhez ajánlott cikkek</h3>
        </div>
        <div className={"blogcarousel"}>
          {
            this.state.blogs.length > 0 &&
            <AliceCarousel duration={duration} fadeOutAnimation buttonsDisabled mouseDragEnabled
                           responsive={wrapBorders} infinite={false}>
              {this.state.blogs.map(value =>
                <BlogPostCard key={value.ID}
                              id={value.ID}
                              title={value.title}
                              text={value.content}
                              background_image={value.background_image}/>
              )}
            </AliceCarousel>
          }
          <NavLink className={"button"} to={"/blog"}>További cikkek olvasása</NavLink>
        </div>
        <br/>
        <br/>
      </div>
    );
  }
}

export default Karrier;
