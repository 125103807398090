import React, {Component} from 'react';
import ExpansionPanel from "@material-ui/core/ExpansionPanel";
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
import Typography from "@material-ui/core/Typography";
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails";
import './SzemelyiKolcson.css';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ContactButton from "../contactbutton/ContactButton";
import Calculator from "../calculator/Calculator";

class AdossagRendezes extends Component {
    state = {
        expanded: '',
    };

    questions = [
        {
            title: 'Mire lehet felhasználni?',
            ans: (
                <div>
                    <ul>
                        <li>személyi kölcsön</li>
                        <li>gépjárműlízing és gépjármű-vásárlási hitel</li>
                        <li>áruhitel</li>
                        <li>folyószámlahitel</li>
                        <li>hitelkártya</li>
                    </ul>
                </div>
            ),
        },
        {
            title: 'Ki veheti fel?',
            ans: (
                <Typography>
                    A kölcsönt minden cselekvőképes természetes személy igényelheti, aki a hitelezési feltételeknek
                    megfelel, illetve aki a hitelbírálat során hitelképesnek minősül.
                </Typography>
            ),
        },
        {
            title: 'Kölcsönösszeg és futamidő?',
            ans: (
                <div>
                    <Typography>
                        Kölcsönösszeg: 400.000 – 10.000.000 Ft között. (10.000 Ft-os lépésközzel)
                        <br/>
                        Futamidő: 36 – 96 hó között. (6 hónapos lépésközzel)
                    </Typography>
                </div>
            ),
        },
        {
            title: 'Mik a kölcsönigénylés feltételei?',
            ans: (
                <div>
                    Az adós és adóstárs:
                    <ul>
                        <li>Betöltötte a 23. életévét</li>
                        <li>Rendelkezik állandó (bejelentett) lakóhellyel</li>
                        <li>Rendelkezik otthoni (az igénylő nevére vagy a bejelentett állandó, esetleg a bejelentett
                            ideiglenes/tartózkodási lakcímére szóló) vezetékes telefonnal vagy mobiltelefonnal
                            (előfizetéses, kártyás vagy munkáltató által biztosított), amelyen elérhető
                        </li>
                        <li>Nyugdíjjal vagy munkából származó rendszeres önálló jövedelemmel rendelkezik, melynek
                            nettó
                            összege az adós esetén ez 125 000Ft-nál nem kevesebb
                        </li>
                        <li>Legalább 3 hónapja valamely magyarországi banknál vezetett lakossági forgalmazói vagy
                            elfogadható jogcímmel (közleménnyel) érkező munkabér- vagy nyugdíjjóváírást tartalmazó
                            bankszámlával rendelkezik
                        </li>
                        <li>Nem szerepel a Központi Hitelinformációs Rendszer (KHR, korábban BAR) negatív listáján
                        </li>
                        <li>Munkahelye nem áll felszámolás, végelszámolás, végrehajtás vagy csődeljárás alatt</li>
                    </ul>
                    Munkaviszonnyal kapcsolatban előírt feltételek:
                    <ul>
                        <li>Az adós (és társas igénylés esetén az adóstárs) jelenlegi munkahelyén legalább 3 hónapja
                            (kivéve: nyugdíjasok)
                        </li>
                        <li>Egyéni vállalkozó esetén minimum 2 éves vállalkozói viszonnyal és 1 teljes lezárt üzleti
                            évvel kell rendelkezni
                        </li>
                        <li>Külföldi munkavállalás esetén a kölcsön lejárata nem haladhatja meg a munkavállalási
                            engedély lejáratától számított 6 hónapot (amennyiben a munkavállalási engedély nem
                            előírás
                            az érintett országban, akkor a munkáltatói jövedelemigazoláson feltüntetett munkaviszony
                            lejáratának időpontját kell figyelembe venni)
                        </li>
                        <li>Jelenleg az igénylő nincs táppénzen</li>
                    </ul>
                    A fenti feltételek nyugdíjas igénylőre csak abban az esetben vonatkoznak, ha az ügyfél a nyugdíj
                    mellett dolgozik, és az abból származó jövedelem figyelembevételét is kéri a minősítés során.
                    <br/>
                    A mellékállásból származó jövedelem is figyelembe vehető, amennyiben a mellékállásra a fent
                    előírt
                    feltételek teljesülnek.
                </div>
            ),
        },
        {
            title: 'Hogyan történik a folyósítás?',
            ans: (
                <Typography>
                    Első körben a kiváltandó hitelek kerülnek kiegyenlítésre, majd a fennmaradó összeget az ügyfél
                    belföldi lakossági folyószámlájára utalja a bank.
                </Typography>
            ),
        },
        {
            title: 'Hogyan történik a törlesztés?',
            ans: (
                <div>
                    <Typography>
                        A törlesztő részlet (kamat + tőke) megfizetése csoportos beszedéssel bankszámláról, vagy
                        átutalással
                        történik.
                    </Typography>

                    <Typography>
                        Törlesztő napként adott hónap 5-e, 10-e, 15-e választható.
                    </Typography>

                    <Typography>
                        A hitel megfizetése a futamidő alatt egyenletes, annuitásos törlesztéssel történik: a havi
                        egyenlő
                        összegű törlesztő részletben növekvő havi tőke, valamint csökkenő havi kamat tartalommal.
                    </Typography>
                </div>
            ),
        },
        {
            title: 'Milyen nyomtatványok és dokumentumok szükségesek az igényléshez?',
            ans: (
                <div>
                    <h5>Érvényes személyazonosító okmány:</h5>
                    <ul>
                        <li>
                            Magyar állampolgár esetén magyar hatóság által kiállított érvényes személyazonosító okirat,
                            mely lehet:
                            <ul>
                                <li>személyi igazolvány*, vagy</li>
                                <li>személyazonosító igazolvány + lakcímet igazoló hatósági igazolvány, vagy</li>
                                <li>kártyaformátumú, 2001. január 1-je után kiállított jogosítvány + lakcímet igazoló
                                    hatósági igazolvány, vagy
                                </li>
                                <li>útlevél + lakcímet igazoló hatósági igazolvány</li>
                            </ul>
                        </li>
                        <li>
                            EGT-s, nem magyar állampolgár esetén:
                            <ul>
                                <li>EGT-s ország hatósága által kiállított érvényes útlevél, vagy</li>
                                <li>EGT-s ország hatósága által kiállított érvényes személyazonosító igazolvány, vagy
                                </li>
                                <li>magyar hatóság által kiállított érvényes személyazonosító igazolvány
                                </li>
                            </ul>
                        </li>
                    </ul>
                    *Ha a személyi igazolvány (könyv formátumú) nem tartalmaz érvényes lakcímet, akkor lakcímet igazoló
                    hatósági igazolvány benyújtása is szükséges.
                    <p>
                        <a href="https://www.erstebank.hu/content/dam/hu/ebh/www_erstebank_hu/Maganszemelyek/hitelek-es-otthon/kolcsonkerelem-nyomtatvanyok-tajekoztatok/szemelyi-kolcson/nyilatkozat_jovedelemrol.pdf"
                           target="_blank" rel="noopener noreferrer" tabIndex="0">
                            <b>–&nbsp;Nyilatkozat jövedelemről és fennálló tartozásról</b>
                        </a>
                        <b><span>&nbsp;</span></b>(nyomtatvány)
                    </p>
                    <p><a
                        href="https://www.erstebank.hu/content/dam/hu/ebh/www_erstebank_hu/Maganszemelyek/hitelek-es-otthon/kolcsonkerelem-nyomtatvanyok-tajekoztatok/szemelyi-kolcson/DK3_Munkaltatoi_igazolas.pdf"
                        target="_blank" rel="noopener noreferrer" tabIndex="0"><b>–&nbsp;Munkáltatói
                        igazolás</b></a><span>&nbsp;</span>(30 napnál nem régebbi,), vagy 6 havi – megfelelő jogcímmel
                        (közleménnyel)** érkező – &nbsp;&nbsp;munkabér-jóváírást tartalmazó bankszámlakivonat
                    </p>
                    <p><b>–&nbsp;Utolsó 3 havi –&nbsp; lehetőleg, munkabér és/vagy nyugdíj jóváírást tartalmazó
                        lakossági bankszámlakivonat</b>,
                    </p>
                    <p><b>–&nbsp;Az utolsó lezárt évről szóló, 30 napnál nem régebbi NAV (APEH)
                        jövedelemigazolás</b><span>&nbsp;</span>szükséges az alábbi esetekben:</p>
                    <ul>
                        <li>az igénylő jövedelme egyéni vállalkozásból (vagy abból is) származik, vagy</li>
                        <li>saját maga vagy családja által tulajdonolt cégben dolgozik (Ekkor munkáltatói
                            jövedelemigazolás is szükséges.)
                        </li>
                    </ul>
                    <p>
                        <b>–&nbsp;Vállalkozói vagy nyugdíjas igazolvány</b>: vállalkozó, illetve nyugdíjas igénylő
                        esetén
                    </p>
                    <p><b>–&nbsp;TB / NYUFIG határozat</b>, amely tartalmazza a nyugdíj vagy a támogatás jogcímét:</p>
                    <ul>
                        <li>55 évnél fiatalabb nyugdíjas igénylő esetén</li>
                        <li>55 évnél idősebb nyugdíjas igénylő esetén, ahol az ügyfél nem tud igazolni 3 havi
                            nyugdíjjóváírást
                        </li>
                    </ul>
                    <p><b>–&nbsp;Rokkantnyugdíjas igénylő esetén</b>:</p>
                    <ul>
                        <li>TB / NYUFIG határozat a rokkantnyugdíj véglegesítéséről vagy annak következő felülvizsgálati
                            időpontjáról
                        </li>
                        <li>NYUFIG év eleji értesítő levél</li>
                    </ul>
                    <p>**Ha nem azonosítható a jogcím a munkabér- vagy nyugdíjjóváírás közlemény rovatából, akkor a
                        jövedelmet külön igazolással (pl. munkáltatói jövedelemigazolással) kell igazolni.
                    </p>
                    <p>
                        Folyószámlahitelkeret, vagy hitelkártya kiváltás esetén utolsó havi
                        kivonat bemutatása is szükséges
                    </p>
                </div>
            ),
        },
    ];

    handleExpansionChange(panel, isExpanded) {
        this.setState({expanded: isExpanded ? panel : ''});
    };

    render() {
        return (
            <div className={"wrapper"}>
                <h3>Adósságrendező kölcsön</h3>
                Várhatóan 2021 december végéig az általunk közvetített személyi hitelek esetében a THM 10,7% - 20,1%
                <div>
                    Csökkentse havi fix kiadásait, váltson most kedvezőbb kamatozású hitelre! Váltsa ki magasabb
                    kamattal rendelkező hiteleit és élvezze
                    havonta megjelenő plusz megtakarítását!
                    <ul>
                        <li>Többféle hitel kiváltására használható</li>
                        <li>Kezes és ingatlanfedezet nélkül, akár 10 millió forintig</li>
                        <li>Fix törlesztő részlet a futamidő végéig</li>
                        <li>A hitelkiváltáson felül extra hitelösszeg is igényelhető</li>
                        <li>400.000-10.000.000 Ft között (10.000 Ft-os lépésközzel)</li>
                        <li>36-96 hó között (6 hónapos lépésközzel)</li>
                    </ul>
                </div>
                <Calculator/>
                <br/>
                <br/>
                <h3>Gyakran ismételt kérdések</h3>
                {
                    this.questions.map((value, index) => {
                        return (
                            <ExpansionPanel key={index} expanded={this.state.expanded === 'panel' + index}
                                            onChange={(event, expanded) => this.handleExpansionChange('panel' + index, expanded)}>
                                <ExpansionPanelSummary
                                    expandIcon={<ExpandMoreIcon/>}
                                >
                                    <p className={"AdossagRendezesExpansionHeading"}>{value.title}</p>
                                </ExpansionPanelSummary>
                                <ExpansionPanelDetails>
                                    {value.ans}
                                </ExpansionPanelDetails>
                            </ExpansionPanel>
                        );
                    })
                }
                <br/>
                <div className={"centeredWrapper"}>
                    <ContactButton/>
                </div>
                <br/>
            </div>
        );
    }
}

export default AdossagRendezes;
