import React, {Component} from 'react';
import BlogPostCard from "./BlogPostCard";
import AliceCarousel from "react-alice-carousel";
import './BlogCarousel.css'
import APICaller from "../../backend/APICaller";
import {NavLink} from "react-router-dom";

class BlogCarousel extends Component {

    constructor(props) {
        super(props);
        this.state = {
            blogs: [],
        };
    }

    componentDidMount() {
        APICaller.getRecommendedBlogs(res => {
            this.setState({
                blogs: res,
            });
        })
    }

    render() {
        let duration = 300;
        let wrapBorders = {
            0: {items: 1},
            850: {items: 2},
            1250: {items: 3},
            1700: {items: 4}
        };
        return (
            <div className={"blogcarousel"}>
                {
                    this.state.blogs.length > 0 &&
                    <AliceCarousel duration={duration} fadeOutAnimation buttonsDisabled mouseDragEnabled
                                   responsive={wrapBorders} infinite={false}>
                        {this.state.blogs.map(value =>
                            <BlogPostCard key={value.ID}
                                          id={value.ID}
                                          title={value.title}
                                          text={value.content}
                                          background_image={value.background_image}/>
                        )}
                    </AliceCarousel>
                }
                <NavLink className={"button"} to={"/blog"}>További cikkek olvasása</NavLink>
            </div>
        )
    }
}

export default BlogCarousel;