import React, {Component} from 'react';
import ExpansionPanel from "@material-ui/core/ExpansionPanel";
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails";
import './Megtakaritasok.css';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ContactButton from "../contactbutton/ContactButton";
import {Typography} from "@material-ui/core";

class Megtakaritasok extends Component {
    state = {
        expanded: '',
    };

    questions = [];

    handleExpansionChange(panel, isExpanded) {
        this.setState({expanded: isExpanded ? panel : ''});
    };

    render() {
        return (
            <div className={"wrapper"}>
                <h3>Megtakarítások</h3>
                <h4>Biztonságban szeretném tudni szeretteimet</h4>
                <div>
                    <Typography>
                        Sokaknak vágya egy új autó, egy lakás vagy a meglévő korszerűsítése. Sokszor felmerül az is,
                        hogy régen volt már a család együtt nyaralni.
                    </Typography>
                    <Typography>
                        Az igazság az, hogy pénzre van szükség ezen vágyaink megvalósításához.
                    </Typography>
                    <Typography>
                        Két lehetőségünk nyílik, vagy megtakarítunk, vagy hitelt veszünk fel.
                    </Typography>
                    <Typography>
                        Döntsön így vagy úgy, ránk számíthat. Szakembereink segítenek nagy gondossággal megválasztani az
                        Önnek leginkább megfelelő pénzügyi megoldást.
                    </Typography>
                </div>
                <br/>
                <h3>Szakember</h3>
                <h4>Szakember segítségére van szükségem, kérem, vegyék fel velem a kapcsolatot!</h4>
                <br/>
                <div className={"centeredWrapper"}><ContactButton text={"Visszahívást kérek!"}/></div>
                <br/>
                <h3>Előnyök</h3>
                <h4>Megtakarításink előnyei</h4>
                <div>
                    <ul>
                        <li><strong>Különböző fontos eseményekre kialakított megtakarítási formák</strong>, legyen
                            az <strong>gyerekének támogatása, saját álmainak megvalósítása, nyugdíjas éveinek anyagi
                                biztonsága, vagy az utolsó nagy kiadás fedezése</strong></li>
                        <li><strong>Tervezhető hozamok</strong></li>
                        <li><strong>Széles választék</strong> az alapok között</li>
                        <li><strong>Nyugdíj cél esetén extra garantált 20%-os hozam</strong></li>
                        <li>Különböző <strong>bónusz</strong>elemek</li>
                        <li><strong>Szakemberek általi ajánlások</strong></li>
                        <li><strong>Személyre szabható</strong></li>
                        <li><strong>Biztosítási védelemmel kiegészíthető</strong></li>
                        <li><strong>Gyorsan lehet alkalmazkodni</strong> a gazdasági környezetre, piaci trendekre</li>
                        <li><strong>Egy szerződéssel, akár több életesemény is fedezhető</strong></li>
                        <li>Tervezhető, világos költségek. <strong>nincsenek váratlan kiadások</strong></li>
                        <li><strong>Kamatadó mentesség</strong> is elérhető</li>
                        <li>Választhat <strong>rendszeres díjas vagy egyszeri megtakarítási formák</strong> között is
                        </li>
                        <li>A biztosítási megtakarítás <strong>nem része a hagyatéki eljárásnak</strong>, így szerettei
                            anyagi biztonságban lehetnek, <strong>nem kell megvárni a hosszú hagyatéki eljárást</strong>
                        </li>
                        <li><strong>Örökösödési adómentes</strong> megtakarítási forma</li>
                    </ul>
                </div>
                {
                    this.questions.length > 0 &&
                    <h3>Gyakran ismételt kérdések</h3>
                }
                {
                    this.questions.map((value, index) => {
                        return (
                            <ExpansionPanel key={index} expanded={this.state.expanded === 'panel' + index}
                                            onChange={(event, expanded) => this.handleExpansionChange('panel' + index, expanded)}>
                                <ExpansionPanelSummary
                                    expandIcon={<ExpandMoreIcon/>}
                                >
                                    <p className={"EletbiztositasExpansionHeading"}>{value.title}</p>
                                </ExpansionPanelSummary>
                                <ExpansionPanelDetails>
                                    {value.ans}
                                </ExpansionPanelDetails>
                            </ExpansionPanel>
                        );
                    })
                }
                <br/>
                <br/>
                <div className={"centeredWrapper"}><ContactButton text={"Visszahívást kérek!"}/></div>
                <br/>
            </div>
        );
    }
}

export default Megtakaritasok;
