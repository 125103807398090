import React, {Component} from 'react'
import './Header.css'
import {NavLink} from "react-router-dom";
import HeaderLogo from "./HeaderLogo";
import HamburgerMenuIcon from "./HamburgerMenuIcon";
import EventSystem from "../../utils/EventSystem";
import ReactGA from "react-ga";
import FBPixel from "../../utils/FBPixel";

class Header extends Component {

  constructor(props) {
    super(props);
    this.state = {
      menuOpened: false,
      windowWidth: window.innerWidth,
      windowHeight: window.innerHeight,
      scrolled: false,
      path: window.document.location.pathname
    };
  }

  menuStateChanged(opened) {
    this.setState({menuOpened: opened});
  }

  componentDidMount() {
    EventSystem.subscribe('urlChanged', () => {
      this.setState({path: window.document.location.pathname});
    });

    window.addEventListener('scroll', () => {
      if (window.scrollY > 0) {
        this.setState({scrolled: true});
      } else {
        this.setState({scrolled: false});
      }
    })
  }

  render() {
    return (
      <div
        className={"navigation-bar " + (this.state.scrolled ? "scrolled" : "") + (this.state.path === "/" ? "homepage" : "")}>
        <NavLink className={"logo-navlink"} to={"/"}>
          <HeaderLogo dark={this.state.scrolled}/>
        </NavLink>
        <div className={"navigation-menu " + (this.state.menuOpened ? 'opened' : 'closed')}>
          <NavLink to={"/kapcsolat"}>
            <div className={"navigation-menu-item"}>Rólunk</div>
          </NavLink>
          <NavLink to={"/hitel"}>
            <div className={"navigation-menu-item"}>Hitel, kölcsön</div>
          </NavLink>
          <NavLink to={"/karrier"}>
            <div className={"navigation-menu-item"}>Karrier</div>
          </NavLink>
          {/*<NavLink to={"/biztositas"}>*/}
          {/*    <div className={"navigation-menu-item"}>Biztosítás</div>*/}
          {/*</NavLink>*/}
          {/*<NavLink to={"/kalkulator"}>*/}
          {/*    <div className={"navigation-menu-item"}>Kalkulátor</div>*/}
          {/*</NavLink>*/}
          {/*<NavLink to={"/provident"}>*/}
          {/*    <div className={"navigation-menu-item"}>Provident</div>*/}
          {/*</NavLink>*/}
          <NavLink to={"/blog"}>
            <div className={"navigation-menu-item"}>Blog</div>
          </NavLink>
          {/*
            <NavDropdown title="Dropdown" id="basic-nav-dropdown">
                <NavDropdown.Item href="#action/3.1">Action</NavDropdown.Item>
                <NavDropdown.Item href="#action/3.2">Another action</NavDropdown.Item>
                <NavDropdown.Item href="#action/3.3">Something</NavDropdown.Item>
                <NavDropdown.Divider/>
                <NavDropdown.Item href="#action/3.4">Separated link</NavDropdown.Item>
            </NavDropdown>
            */}
        </div>
        <HamburgerMenuIcon menuStateChanged={this.menuStateChanged.bind(this)}/>
      </div>
    );
  }
}

export default Header;
