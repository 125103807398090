import React, {Component} from "react";
import styled from "styled-components";
import {NavLink as NavLink2} from "react-router-dom";
import career_img_team from "../../assets/career/team.jpg";
import career_img_office from "../../assets/career/office.jpg";
import career_img_stability from "../../assets/career/stability.jpg";
import career_img_training from "../../assets/career/training.jpg";
import career_img_partners from "../../assets/career/partners.jpg";
import career_img_fun from "../../assets/career/fun.jpg";

const NavLink = styled(NavLink2)`
  margin: 0 auto;
`;

const CareerRedirectContent = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  background-color: white;
  padding: 24px 12px;
`;

const CareerHeadline = styled.h1`
  width: 100%;
  font-size: 24pt;
  text-align: center;
  font-family: "Helvetica Neue", sans-serif;
  margin: 0 0 12px 0;
  padding: 0;
`;

const BlocksList = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
`;

const CareerBlock = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  margin: 24px 12px;
  width: 450px;
  padding: 12px;
  border-radius: 10px;
  background-color: #fafafa;
  box-shadow: 0 0 4px 0 rgba(121, 121, 121, 0.66);
  color: #343434;

  min-height: ${({i}) => 300 + 30 * (i % 3)}px;

  & > h2 {
    font-family: "Helvetica Neue", sans-serif;
    font-size: 14pt;
    font-weight: bold;
    margin: 0 0 12px 0;
  }

  & > img {
    width: 100%;
    margin-bottom: 6px;
  }

  @media screen and (max-width: 1400px) {
    width: 400px;
  }

  @media screen and (max-width: 1000px) {
    width: 330px;
    min-height: ${({i}) => 300 + 30 * (i % 2)}px;
  }

  @media screen and (max-width: 700px) {
    margin: 12px 6px;
    min-height: 300px;
  }
`;


export default class CareerBlocks extends Component {
  state = {
    showButtons: false,
    long: false,
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.state.showButtons !== this.props.showButtons || this.state.long !== this.props.long)
      this.reloadProps();
  }

  reloadProps() {
    this.setState({
      showButtons: this.props.showButtons,
      long: this.props.long
    })
  }

  componentDidMount() {
    this.reloadProps();
  }

  render() {
    return (
      <CareerRedirectContent>
        <CareerHeadline>Miért jó nálunk dolgozni?</CareerHeadline>
        {this.state.showButtons &&
        <NavLink to={"/karrier"}>
          <div className={"button"}>
            Nyitott pozícióink
          </div>
        </NavLink>
        }
        <BlocksList>
          <CareerBlock i={0}>
            <h2>Milyenek az emberek?</h2>
            <img src={career_img_team} alt={"csapat"}/>
            <p>Támogató, barátságos környezet és csapat</p>
          </CareerBlock>
          <CareerBlock i={1}>
            <h2>Így néz ki az irodánk</h2>
            <img src={career_img_office} alt={"iroda"}/>
            <p>Nagyon sokat törődünk azzal, hogy az irodánk inspiráló munkakörnyezetet adjon, ahova az emberek
              szeretnek bejárni.</p>
          </CareerBlock>
          <CareerBlock i={2}>
            <h2>Ennyit lehet keresni</h2>
            <img src={career_img_stability} alt={"juttatások"}/>
            <p>
              Minden mellett a munka fontos része az is, hogy mennyit keres a munkatárs. Azt valljuk, hogy a
              Credithomenál a becsületes és szorgalmas munkatársnak minél több fejfájást spóroljunk meg az anyagiak
              miatt. Így tudunk a legjobban a munkára fókuszálni.
            </p>
          </CareerBlock>
          <CareerBlock i={3}>
            <h2>Munkatársak oktatása</h2>
            <img src={career_img_training} alt={"kollega"}/>
            <p>Kollégáinkat folyamatosan képezzük személyes tanfolyamokkal, oktatásokkal és egyéni
              foglalkozásokkal.</p>
          </CareerBlock>
          <CareerBlock i={4}>
            <h2>Partnereink</h2>
            <img src={career_img_partners} alt={"partnerek"}/>
            <p>Velük dolgozunk együtt: Cetelem, OTP, Budapest, Ertse. Beleláthatsz, hogyan működik a banki
              vállalatok világa</p>
          </CareerBlock>
          <CareerBlock i={5}>
            <h2>Miért szeretünk itt dolgozni?</h2>
            <img src={career_img_fun} alt={"love"}/>
            <p>
              1. Menő munka, menő emberekkel<br/>
              2. Folyamatosan tudok miben fejlődni<br/>
              3. Jó pénzt tudok itt keresni<br/>
            </p>
          </CareerBlock>
        </BlocksList>
        {this.state.showButtons &&
        <NavLink to={"/karrier"}>
          <div className={"button"}>
            Nyitott pozícióink
          </div>
        </NavLink>
        }
      </CareerRedirectContent>
    );
  }
}
