import 'url-search-params-polyfill';

const baseURL = 'https://credithome.hu/backend';

function ownFetch(method, url, params, callback) {
  console.log(baseURL + url + '::' + method + ' called');
  let formData = new URLSearchParams();

  if (params) {
    for (let key in params)
      if (params.hasOwnProperty(key))
        formData.append(key, params[key]);
  }

  let init = {
    method: method,
    headers: {
      'Accept': 'application/text',
      'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
    },
    credentials: 'include',
    withCredentials: true,
  };

  if (method !== 'GET') {
    init.body = formData;
  }

  fetch(baseURL + url, init
  ).then(res => {
    return res.text();
  }).then(
    res => {
      if (callback)
        callback(res);
    }
  ).catch(reason => {
    console.log(reason);
  });
}

export default {
  baseURL,
  login: (email, pass, cb) => {
    ownFetch("POST", "/user/login.php", {email, pass}, cb);
  },

  checkLoginState: (cb) => {
    ownFetch("GET", "/user/status.php", {}, cb)
  },

  logout: () => {
    ownFetch("POST", "/user/logout.php");
  },

  getAllUsers: (callback) => {
    ownFetch("GET", "/user/getall.php", {}, (res) => {
      callback(JSON.parse(res));
    })
  },

  deleteUser: (id) => {
    ownFetch("POST", "/user/delete.php", {id});
  },
  modifyUser(id, email, pass, admin) {
    ownFetch("POST", "/user/modify.php", {id, email, pass, admin});
  },
  addNewUser(email, pass, admin) {
    ownFetch("POST", "/user/register.php", {email, pass, admin}, (res) => {
      console.log(res);
    });
  },

  sendFile(folderID, file, callback) {
    let formData = new FormData();
    formData.append('document', file, file.name);

    let xhr = new XMLHttpRequest();
    xhr.open('POST', baseURL + '/files/upload.php?folderID=' + folderID, true);
    xhr.withCredentials = true;
    xhr.onload = () => {
      if (xhr.status === 200) {
        if (callback)
          callback(xhr.responseText);
      } else {
        if (callback)
          callback(false);
      }
    };
    xhr.send(formData);
  },

  getAllFiles(callback) {
    ownFetch("GET", "/files/getall.php", {}, (res) => {
      callback(JSON.parse(res));
    })
  },
  deleteFile(id) {
    ownFetch("POST", "/files/delete.php", {id});
  },
  getAllBlogs(callback) {
    ownFetch("GET", "/blog/getall.php", {}, (res) => {
      callback(JSON.parse(res));
    });
  },
  getRecommendedBlogs(callback) {
    ownFetch("GET", "/blog/getRecommended.php", {}, (res) => {
      callback(JSON.parse(res));
    });
  },
  saveBlog(blog, callback) {
    ownFetch("POST", "/blog/save.php", blog, (res) => {
      if (callback) callback(res);
    });
  },
  deleteBlog(id) {
    ownFetch("POST", "/blog/delete.php", {id: id});
  },
  deleteFolder(id) {
    ownFetch("POST", "/folders/delete.php", {id: id});
  },
  uploadBlogBackground(file, callback) {
    let formData = new FormData();
    formData.append('document', file, file.name);

    let xhr = new XMLHttpRequest();
    xhr.open('POST', baseURL + '/files/upload_blog.php', true);
    xhr.withCredentials = true;
    xhr.onload = () => {
      if (xhr.status === 200) {
        if (callback)
          callback(xhr.responseText);
      } else {
        if (callback)
          callback(false);
      }
    };
    xhr.send(formData);
  },
  uploadCV(file, callback) {
    let formData = new FormData();
    formData.append('document', file, file.name);

    let xhr = new XMLHttpRequest();
    xhr.open('POST', baseURL + '/files/upload_cv.php', true);
    xhr.withCredentials = true;
    xhr.onload = () => {
      if (xhr.status === 200) {
        if (callback)
          callback(xhr.responseText);
      } else {
        if (callback)
          callback(false);
      }
    };
    xhr.send(formData);
  },
  getBlog(id, callback) {
    ownFetch("GET", "/blog/get.php?id=" + id, {}, res => {
      if (callback)
        callback(JSON.parse(res));
    });
  },
  saveFolder(foldername, callback) {
    ownFetch("POST", "/folders/save.php", {foldername, ID: -1}, (res) => {
      if (callback) callback(res);
    });
  },
  getAllFolders(callback) {
    ownFetch("GET", "/folders/getall.php", {}, (res) => {
      callback(JSON.parse(res));
    })
  },
  sendRequest(editFirstName, editLastName, editEmail, editTel, editMsg) {
    ownFetch("POST", "/request.php", {
        firstname: editFirstName,
        lastname: editLastName,
        email: editEmail,
        tel: editTel,
        msg: editMsg,
      },
      (res) => {
        console.log(res)
      }
    );
  },

  sendCV(editFirstName, editLastName, editEmail, editTel, cvFileName, title, cb: ()=>{}) {
    ownFetch("POST", "/cv.php", {
        firstname: editFirstName,
        lastname: editLastName,
        email: editEmail,
        tel: editTel,
        cvFileName: cvFileName,
        title: title,
      },
      (res) => {
        if (cb)
          cb(res);
      }
    );
  },
  changePass(password) {
    ownFetch("POST", "/user/changePass.php", {password: password}, (res) => {
      console.log(res)
    });
  },
  getAllJobs(callback) {
    ownFetch("GET", "/jobs/getall.php", {}, (res) => {
      callback(JSON.parse(res));
    });
  },
  saveJob(job, callback) {
    ownFetch("POST", "/jobs/save.php", job, (res) => {
      if (callback) callback(res);
    });
  },
  deleteJob(id) {
    ownFetch("POST", "/jobs/delete.php", {id: id});
  },
  getJob(id, callback) {
    ownFetch("GET", "/job/get.php?id=" + id, {}, res => {
      if (callback)
        callback(JSON.parse(res));
    });
  },

};
