import React, {Component} from 'react';
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import {isMobile} from "react-device-detect";
import Tab from "@material-ui/core/Tab";
import SwipeableViews from "react-swipeable-views";
import TabContainer from "react-bootstrap/TabContainer";
import Vagyonbiztositas from "../components/biztositasok/Vagyonbiztositas";
import Lakasbiztositas from "../components/biztositasok/Lakasbiztositas";
import Eletbiztositas from "../components/biztositasok/Eletbiztositas";
import Megtakaritasok from "../components/biztositasok/Megtakaritasok";

class Biztositasok extends Component {
    state = {
        value: 0,
    };

    handleChange(newValue) {
        this.setState({value: newValue});
    }

    render() {
        return (
            <div className={"page"}>
                <AppBar position="static" color="default">
                    <Tabs
                        value={this.state.value}
                        onChange={(e, i) => this.handleChange(i)}
                        indicatorColor="primary"
                        textColor="primary"
                        variant={isMobile ? 'scrollable' : 'standard'}
                        scrollButtons="auto"
                        centered={!isMobile}
                    >
                        <Tab label="Vagyonbiztosítás"/>
                        <Tab label="Lakásbiztosítás"/>
                        <Tab label="Életbiztosítás"/>
                        <Tab label="Megtakarítások"/>
                    </Tabs>
                </AppBar>
                <SwipeableViews
                    index={this.state.value}
                    onChangeIndex={(e, i) => this.handleChange(i)}
                >
                    <TabContainer><Vagyonbiztositas/></TabContainer>
                    <TabContainer><Lakasbiztositas/></TabContainer>
                    <TabContainer><Eletbiztositas/></TabContainer>
                    <TabContainer><Megtakaritasok/></TabContainer>
                </SwipeableViews>
            </div>
        );
    }
}

export default Biztositasok;