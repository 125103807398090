import React, {Component} from 'react';
import APICaller from "../backend/APICaller";
import '../css/BlogPost.css';
import BlogCarousel from "../components/blog/BlogCarousel";
import ContactButton from "../components/contactbutton/ContactButton";
import EventSystem from "../utils/EventSystem";

class BlogPost extends Component {
    constructor(props) {
        super(props);
        this.state = {
            blog: {},
        };
    }

    updatePage() {
        let id = this.props.match.params.id;

        if (id === undefined)
            id = 1;

        APICaller.getBlog(id, res => {
            this.setState({
                blog: res,
            });
        });
    }

    componentDidMount() {
        EventSystem.subscribe("blogURLChanged", () => {
            this.updatePage();
        });
        this.updatePage();
    }

    render() {
        return (
            <div className={"page"}>
                <div className={"blogPostDetails"}>
                    <div className={"blogPostBackgroundImageWrapper"}>
                        <img alt={"blogPost_hatterkep"} src={this.state.blog.background_image}
                             className={"blogPostBackgroundImage"}/>
                    </div>
                    {/*<div className={"blogPostTitle_OneBlog"}>{this.state.blog.title}</div>*/}
                    <div className={"blogPostContentContainer"}>
                        <h1>{this.state.blog.title}</h1>
                        <div className={"blogPostContent"} dangerouslySetInnerHTML={{__html: this.state.blog.content}}/>
                    </div>
                </div>
                <div className={"centeredWrapper"}>
                    <ContactButton/>
                </div>
                <div className={"linebg"}>
                    <h1>További cikkeink</h1>
                    <BlogCarousel/>
                </div>
            </div>
        );
    }
}

export default BlogPost;