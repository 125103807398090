import React, {Component} from 'react';
import APICaller from "../backend/APICaller";
import '../css/Blogs.css';
import ContactButton from "../components/contactbutton/ContactButton";
import bg from '../assets/blog/blogs_bg_img1.jpg';
import BlogPostCard from "../components/blog/BlogPostCard";

class Blogs extends Component {
  constructor(props) {
    super(props);
    this.state = {
      blogs: [],
    };
  }

  componentDidMount() {
    APICaller.getAllBlogs(res => {
      this.setState({
        blogs: res.filter(e => e.deleted === 0),
      });
    });
  }

  render() {
    return (
      <div className={"page"}>
        <div className={"blogDetails"}>
          <img className={"blogBackgroundImage"} src={bg} alt={"Credithome blog hatterkep"}/>
          <div className={"blogTitle_OneBlog"}>
            Olvasson rólunk!
            <div className={"blogSubTitle_OneBlog"}>Tudja meg, miként gondolkozunk a világról!</div>
            <div className={"centeredWrapper"}>
              <ContactButton/>
            </div>
          </div>
          <div className={"blogContentContainer"}>
            <h1>Válogasson a lenti cikkeink közül!</h1>
            <div className={"blogPostContent"}>
              <div className={"blog_Posts"}>
                {this.state.blogs.length > 0 && this.state.blogs.map(value => {
                  return (
                    <div className={"blogs_BlogPost"}>
                      <BlogPostCard key={value.ID} background_image={value.background_image}
                                    id={value.ID}
                                    text={value.content} title={value.title}/>
                    </div>
                  );
                })}
              </div>
              <div className={"centeredWrapper"}>
                <ContactButton/>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Blogs;
