import React, {Component} from 'react';
import ExpansionPanel from "@material-ui/core/ExpansionPanel";
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails";
import './Lakasbiztositas.css';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ContactButton from "../contactbutton/ContactButton";
import {Typography} from "@material-ui/core";

class Lakasbiztositas extends Component {
    state = {
        expanded: '',
    };

    questions = [];

    handleExpansionChange(panel, isExpanded) {
        this.setState({expanded: isExpanded ? panel : ''});
    };

    render() {
        return (
            <div className={"wrapper"}>
                <h3>Lakásbiztosítás</h3>
                <h4>Biztonságban szeretném tudni az otthonomat</h4>
                <div>
                    <Typography>
                        Általában legértékesebb vagyontárgyunk az otthonunk.
                    </Typography>
                    <Typography>
                        Egy jelentős kiadás esetén, sajnos sok esetben nem áll rendelkezésre az elegendő anyagi tartalék
                        otthonunk megóvására, felújítására.
                    </Typography>
                    <Typography>
                        Tűz, vihar, villám, csőtörés vagy egy betörés esetén mindig jól jön egy megfelelően
                        megválasztott biztosítás.
                    </Typography>
                    <Typography>
                        Szakértő kollégáink segítenek megtalálni a legjobb megoldást az Ön számára.
                    </Typography>
                </div>
                <br/>
                <h3>Szakember</h3>
                <h4>Szakember segítségére van szükségem, kérem, vegyék fel velem a kapcsolatot!</h4>
                <br/>
                <div className={"centeredWrapper"}><ContactButton text={"Visszahívást kérek!"}/></div>
                <br/>
                <h3>Előnyök</h3>
                <h4>Lakásbiztosítás előnyei</h4>
                <div>
                    <ul>
                        <li><b>Teljes körű</b> lakásbiztosítás <b>számos variálási lehetőséggel</b></li>
                        <li><b>Alapkockázatok</b>: tűz, szikla/kőomlás, földcsuszamlás, árvíz, robbanás, ismeretlen
                            építmény és üreg beomlása, földrengés, villámcsapás, idegen jármű ütközése, vízkár, vihar,
                            légi jármű ütközése, betöréses lopás, rablás, rongálás, jégverés, idegen tárgyak rádőlése,
                            üvegtörés, Hó nyomás, felhőszakadás, felelősségbiztosítás
                        </li>
                        <li><b>Rugalmas, ügyfélbarát kárrendezés</b></li>
                        <li><b>24 órás segítségnyújtás</b></li>
                        <li><strong>Személyre szabható</strong> számos kiegészítőnek köszönhetően
                            <ul>
                                <li>villámcsapás kiegészítő</li>
                                <li>beázás</li>
                                <li>különleges üvegek</li>
                                <li>zárcsere</li>
                                <li>bankkártya pótlása</li>
                                <li>baleseti műtétéi térítés</li>
                                <li>csonttörés biztosítása</li>
                                <li>dugulás elhárítás</li>
                                <li>vízveszteség térítése csőtörés esetén</li>
                                <li>szabadban tárolt vagyontárgyak biztosítása</li>
                                <li>kerti veszélytelenítés költségtérítése</li>
                                <li>sporteszközök biztosítása</li>
                                <li>kórházi lopás</li>
                                <li>égési sérülések biztosítása</li>
                                <li>üdülésre elvitt vagyontárgyak biztosítása</li>
                                <li>mester ember ajánlása</li>
                                <li>gyerekektől való iskolai lopás</li>
                                <li>gyerekbetegségek térítése</li>
                                <li>napkollektor, napelem, hőszivattyú biztosítása</li>
                            </ul>
                        </li>
                    </ul>
                </div>
                {
                    this.questions.length > 0 &&
                    <h3>Gyakran ismételt kérdések</h3>
                }
                {
                    this.questions.map((value, index) => {
                        return (
                            <ExpansionPanel key={index} expanded={this.state.expanded === 'panel' + index}
                                            onChange={(event, expanded) => this.handleExpansionChange('panel' + index, expanded)}>
                                <ExpansionPanelSummary
                                    expandIcon={<ExpandMoreIcon/>}
                                >
                                    <p className={"LakasbiztositasExpansionHeading"}>{value.title}</p>
                                </ExpansionPanelSummary>
                                <ExpansionPanelDetails>
                                    {value.ans}
                                </ExpansionPanelDetails>
                            </ExpansionPanel>
                        );
                    })
                }
                <br/>
                <br/>
                <div className={"centeredWrapper"}><ContactButton text={"Visszahívást kérek!"}/></div>
                <br/>
            </div>
        );
    }
}

export default Lakasbiztositas;
