import React, {Component} from "react";
import './Footer.css'
import Svg037FacebookLetterLogo from "../../Icons/icons/037FacebookLetterLogo";
import Svg015Contact from "../../Icons/icons/015Contact";
import Svg014PhoneCall from "../../Icons/icons/014PhoneCall";
import {NavLink} from "react-router-dom";


class Footer extends Component {
  render() {
    let year = new Date().getFullYear();
    return (
      <footer>
        <div className={"footerContainer"}>
          <div className={"footerElement"}>
            <h5>Rólunk</h5>
            <NavLink to={"/kapcsolat"}>Kapcsolat</NavLink><br/>
            <NavLink to={"/blog"}>Cikkek</NavLink><br/>
            <NavLink to={"/adatkezeles"}>Adatkezelés</NavLink><br/>
            <NavLink to={"/karrier"}>Karrier</NavLink><br/>
          </div>
          <div className={"footerElement"}>
            <h5>Partnereink</h5>
            {/*<NavLink to={"/provident"}>Provident</NavLink><br/>*/}
            <NavLink to={"/partner"}>Partner portál</NavLink>
          </div>
          <div className={"footerElement"}>
            <h5>Hitelek</h5>
            <NavLink to={"/hitel"}>Személyi kölcsön</NavLink><br/>
            <NavLink to={"/hitel"}>Adósságrendezés</NavLink><br/>
            <NavLink to={"/hitel"}>Személyi hitel</NavLink>
          </div>
          {/*
                    <div className={"footerElement"}>
                        <h5>Biztosítások</h5>
                        <NavLink to={"/biztositas"}>Lakásbiztosítás</NavLink><br/>
                        <NavLink to={"/biztositas"}>Vagyonbiztosítás</NavLink><br/>
                        <NavLink to={"/biztositas"}>Élet, egészség, baleset</NavLink><br/>
                        <NavLink to={"/biztositas"}>Megtakarítások</NavLink>
                    </div>
                    */}
          <div className={"footerElement"}>
            <h5>Elérhetőségek</h5>
            <a href={"tel:+3617982945"}>
              <Svg014PhoneCall width="20" height="20" viewBox={"0 0 40 40"}
                               className={"footer_icon "} fill={"white"}/>
              +36 1 798 2945
            </a><br/>

            <a href={"mailto:service@credithome.hu"}>
              <Svg015Contact width="20" height="20" viewBox={"0 0 50 50"}
                             className={"footer_icon "} fill={"white"}/>
              service@credithome.hu
            </a><br/>

            <a href={"https://www.facebook.com/credithome.hu/"}>
              <Svg037FacebookLetterLogo width="20" height="20" viewBox={"0 0 500 500"}
                                        className={"footer_icon "} fill={"white"}/>
              Facebook
            </a><br/>

          </div>
          <div className={"copyright"}>
            Copyright © 2014-{year} CreditHome, Készítette: <a href={"https://opthink.com"}>OpThink</a> & <a
            href={"http://valiantnetworks.hu"}>ValiantNetworks</a>
          </div>
        </div>
      </footer>
    );
  }
}

export default Footer;
