import React, {Component} from "react";
import './Users.css'
import Svg018RubbishBin from "../../Icons/icons/018RubbishBin";
import Svg029ForwardArrow from "../../Icons/icons/029ForwardArrow";
import APICaller from "../../backend/APICaller";
import {Button, FormControlLabel, Modal} from "@material-ui/core";
import TextField from "@material-ui/core/TextField";
import Checkbox from "@material-ui/core/Checkbox";

class Users extends Component {

    constructor(props) {
        super(props);
        this.state = {
            users: [],
            modalOpen: false,
            editID: -1,
            editEmail: "",
            editPass: "",
            editAdmin: false,
            newUser: false,
        }
    }

    componentDidMount() {
        APICaller.getAllUsers(res => {
            if (res)
                this.setState({users: res})
        });
    }

    save() {
        if (this.state.newUser) {
            APICaller.addNewUser(this.state.editEmail, this.state.editPass, this.state.editAdmin);
            let users = this.state.users;
            users.push({
                id: users[users.length - 1].ID + 1,
                email: this.state.editEmail,
                pass: this.state.editPass,
                admin: this.state.editAdmin ? 1 : 0,
            });

            this.setState({
                users: users,
                modalOpen: false,
                editEmail: "",
                editPass: "",
                editAdmin: false,
            });
        } else {
            APICaller.modifyUser(this.state.editID, this.state.editEmail, this.state.editPass, this.state.editAdmin);
            let newUsers = this.state.users;
            let u = newUsers.find(v => v.ID === this.state.editID);
            u.email = this.state.editEmail;
            u.pass = this.state.editPass;
            u.admin = this.state.editAdmin;

            this.setState({
                users: newUsers,
                modalOpen: false,
                editID: -1,
                editEmail: "",
                editPass: "",
                editAdmin: false,
            })
        }
    }

    modifyUser(id) {
        let user = this.state.users.filter(v => v.ID === id)[0];

        this.setState({
            modalOpen: true,
            editEmail: user.email,
            editID: user.ID,
            editAdmin: user.admin,
            newUser: false,
        });
    }

    deleteUser(id) {
        APICaller.deleteUser(id);
        this.setState({users: this.state.users.filter(user => user.ID !== id)})
    }

    render() {
        return (
            <div className={"usersContainer"}>
                <Modal onClose={() => this.setState({modalOpen: false})} open={this.state.modalOpen}>
                    <div className={"userEditingModal"}>
                        <div className={"userEditingModalContent"}>
                            <TextField
                                label="E-mail"
                                type="email"
                                name="email"
                                value={this.state.editEmail}
                                onChange={e => this.setState({editEmail: e.target.value})}
                                autoComplete="email"
                                margin="normal"
                                variant="outlined"
                            />
                            <TextField
                                label="Jelszó"
                                type="password"
                                name="password"
                                value={this.state.editPass}
                                onChange={e => this.setState({editPass: e.target.value})}
                                autoComplete="email"
                                margin="normal"
                                variant="outlined"
                            />
                            <FormControlLabel control={
                                <Checkbox
                                    checked={this.state.editAdmin}
                                    onChange={e => this.setState({editAdmin: e.target.checked})}
                                />}
                                              label={"Admin"}
                            />

                            <div className={"modalactions"}>
                                <Button onClick={() => this.save()} variant="outlined" size="small"
                                        color="primary">
                                    Mentés
                                </Button>
                                <Button onClick={() => this.setState({modalOpen: false})} variant="outlined"
                                        size="small"
                                        color="primary">
                                    Mégsem
                                </Button>
                            </div>
                        </div>
                    </div>
                </Modal>
                <Button onClick={() => this.setState({modalOpen: true, newUser: true})} variant="outlined"
                        size="small"
                        color="primary">
                    Új felhasználó
                </Button>
                <div className={"user"}>
                    <div className={"email"}><b>Email cím</b></div>
                    <div className={"role"}><b>Felhasználói szerep</b></div>
                    <div className={"actions"}>
                        <b>Műveletek</b>
                    </div>
                </div>
                {this.state.users.length > 0 && this.state.users.map(val => {
                    return (
                        <div key={val.ID} className={"user"}>
                            <div className={"email"}>{val.email}</div>
                            <div
                                className={"role"}>{val.admin === 1 ? "admin" : "felhasználó"}</div>
                            {val.ID !== 1 &&
                            <div className={"actions"}>
                                <div onClick={() => this.modifyUser(val.ID)} className={"modify"}>
                                    <Svg029ForwardArrow
                                        width={20} height={20}
                                        viewBox={"0 0 450 450"}/>
                                </div>
                                <div onClick={() => this.deleteUser(val.ID)} className={"delete"}><Svg018RubbishBin
                                    width={20}
                                    height={20}
                                    viewBox={"0 0 450 450"}/>
                                </div>
                            </div>
                            }
                        </div>
                    );
                })
                }
            </div>
        );
    }

}

export default Users;
