import React, {Component} from 'react';
import './ContactButton.css'
import {Modal} from "@material-ui/core";
import TextField from "@material-ui/core/TextField";
import {validate} from "email-validator";
import APICaller from "../../backend/APICaller";
import Button from "@material-ui/core/Button";

class ContactButton extends Component {

    constructor(props) {
        super(props);
        this.state = {
            open: false,
            editFirstName: "",
            editLastName: "",
            editEmail: "",
            editTel: "",
            editMsg: "",
            tried: false,
        };
    }

    send() {
        this.setState({tried: true});
        if (this.checkFirstName() || this.checkLastName() || !validate(this.state.editEmail) || this.checkTel())
            return;

        APICaller.sendRequest(
            this.state.editFirstName,
            this.state.editLastName,
            this.state.editEmail,
            this.state.editTel,
            this.state.editMsg,
        );

        this.setState({
            editFirstName: '',
            editLastName: '',
            editEmail: '',
            editTel: '',
            editMsg: '',
            open: false,
            tried: false,
        })
    }

    checkFirstName() {
        return this.state.editFirstName.length <= 3;
    }

    checkLastName() {
        return this.state.editLastName.length <= 3;
    }

    checkTel() {
        return this.state.editTel.length < 7;
    }

    render() {
        return (
            <div>
                <Modal onClose={() => this.setState({open: false})} open={this.state.open}>
                    <div className={"contactFormModal"}>
                        <div className={"contactForm"}>
                            <h3>Kérlek töltsd ki az alábbi űrlapot, hogy mihamarabb el tudjunk érni!</h3>
                            <TextField
                                label="Vezetéknév"
                                type="lastname"
                                error={this.state.tried && this.checkLastName()}
                                value={this.state.editLastName}
                                onChange={e => this.setState({editLastName: e.target.value})}
                                margin="normal"
                                variant="outlined"
                            />
                            <TextField
                                label="Keresztnév"
                                type="firstname"
                                error={this.state.tried && this.checkFirstName()}
                                value={this.state.editFirstName}
                                onChange={e => this.setState({editFirstName: e.target.value})}
                                margin="normal"
                                variant="outlined"
                            />
                            <TextField
                                label="E-mail cím"
                                type="e-mail"
                                error={this.state.tried && !validate(this.state.editEmail)}
                                value={this.state.editEmail}
                                onChange={e => this.setState({editEmail: e.target.value})}
                                margin="normal"
                                variant="outlined"
                            />
                            <TextField
                                label="Telefonszám"
                                type="tel"
                                error={this.state.tried && this.checkTel()}
                                value={this.state.editTel}
                                onChange={e => this.setState({editTel: e.target.value})}
                                margin="normal"
                                variant="outlined"
                            />
                            <TextField
                                label="További üzenet"
                                type="text"
                                multiline
                                rows={5}
                                value={this.state.editMsg}
                                onChange={e => this.setState({editMsg: e.target.value})}
                                margin="normal"
                                variant="outlined"
                            />
                            <div className={"centeredWrapper"}>
                                <Button variant="outlined" color="primary" onClick={() => this.send()}>
                                    Küldés
                                </Button>
                                <br/>
                                <Button variant="outlined" color="primary" onClick={() => this.setState({open: false})}>
                                    Mégsem
                                </Button>
                            </div>
                        </div>
                    </div>
                </Modal>
                <div onClick={() => this.setState({open: true})} className={"button"}>
                    {this.props.text ? this.props.text : 'Visszahívást kérek'}
                </div>
            </div>
        );
    }
}

export default ContactButton;
