import React, {Component} from 'react';
import APICaller from "../backend/APICaller";
import '../css/BlogPost.css';
import ApplyButton from "../components/applyButton/ApplyButton";

class JobPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      job: {},
    };
  }

  updatePage() {
    let id = this.props.match.params.id;

    if (id === undefined)
      id = 1;

    APICaller.getJob(id, res => {
      this.setState({
        job: res,
      });
    });
  }

  componentDidMount() {
    this.updatePage();
  }

  render() {
    return (
      <div className={"page"}>
        <div className={"blogPostDetails"}>
          <div className={"blogPostBackgroundImageWrapper"}>
            <img alt={"blogPost_hatterkep"} src={this.state.job.background_image}
                 className={"blogPostBackgroundImage"}/>
          </div>
          {/*<div className={"blogPostTitle_OneBlog"}>{this.state.blog.title}</div>*/}
          <div className={"blogPostContentContainer"}>
            <h1>{this.state.job.title}</h1>
            <div className={"blogPostContent"} dangerouslySetInnerHTML={{__html: this.state.job.content}}/>
          </div>
        </div>
        <div className={"centeredWrapper"}>
          <ApplyButton title={this.state.job.title}/>
        </div>
      </div>
    );
  }
}

export default JobPage;
