import React, {Component} from 'react';
import './TeamMember.css'
import pointer from '../../assets/icons/pointer.png';

class TeamMember extends Component {

    state = {
        mouseOn: false,
        anim: true,
    };

    componentDidMount() {
        this.setState({
            anim: this.props.anim,
        });
    }

    render() {
        return (
            <div className={"TeamMember-wrapper" + (this.state.anim ? ' anim' : '')}
                 onMouseEnter={() => {
                     if (!this.props.new)
                         this.setState({mouseOn: true, anim: false})
                 }}
                 onMouseLeave={() => {
                     if (!this.props.new)
                         this.setState({mouseOn: false, anim: false})
                 }}
                 onTouchStart={() => {
                     if (!this.props.new)
                         this.setState({mouseOn: !this.state.mouseOn, anim: false});
                 }}
                 onTouchEnd={() => {
                     if (!this.props.new)
                         this.setState({mouseOn: !this.state.mouseOn, anim: false});
                 }}
            >
                {this.state.anim &&
                <img width={"19px"} className={"mouseIcon"} alt={"mouse"} src={pointer}/>
                }
                <div className={"TeamMember-innerWrapper" + (this.state.mouseOn ? ' hover' : '')}>
                    <div className={"front" + (this.state.mouseOn ? ' hidden' : '')}>
                        <h4>{this.props.name}</h4>
                        <h5>{this.props.post}</h5>
                        <p>
                            {this.props.text}
                        </p>
                        {
                            this.props.new &&
                            <p style={{textAlign: "center", fontSize: "15px"}}>
                                Nézd meg <a href={"/karrier"}>itt</a> a karrier lehetőségeinket és nyílt
                                pozíciónkat!<br/>
                                <a href={"mailto:karrier@credithome.hu"}>karrier@credithome.hu</a>
                            </p>
                        }
                    </div>
                    <div className={"back" + (this.state.mouseOn ? '' : ' hidden')}>
                        {this.props.image &&
                        <img src={this.props.image} alt={"Csapattag"}
                             className={'' + (this.props.new ? ' fit' : '')}/>
                        }
                    </div>
                </div>
            </div>
        );
    }
}

export default TeamMember;
