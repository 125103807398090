import React, {Component} from "react";
import './BlogPostCard.css'
import htmlToText from "html-to-text";
import {NavLink} from "react-router-dom";

class BlogPostCard extends Component {

  render() {
    return (
      <div className={"blogpostcard"}>
        <article className={"blogPostCardWrapper"}>
          <img src={this.props.background_image} alt={"cikk_hatterkep"}/>
          <div className={"blogpost_title"}>
            <h6>{this.props.title}</h6>
          </div>
          <div className={"blogpost_content"}>
            {htmlToText.fromString(this.props.text, {
              wordWrap: 80,
              ignoreImage: true,
              ignoreHref: true,
              preserveNewlines: true,
              longWordSplit: 'wrapCharacters',
            })}
          </div>
          <div className={"blogpost_button"}>
            <NavLink to={"/blog/" + this.props.id}>
              Elolvasom
            </NavLink>
          </div>
        </article>
      </div>
    )
  }
}

export default BlogPostCard;
