import React, {Component} from 'react';
import "../css/Partner.css"
import TextField from "@material-ui/core/TextField";
import HamburgerMenuIcon from "../components/header/HamburgerMenuIcon";
import APICaller from "../backend/APICaller";
import Files from "../components/partner/Files";
import Users from "../components/partner/Users";
import Blogs from "../components/partner/Blogs";
import {Modal} from "@material-ui/core";
import Button from "@material-ui/core/Button";
import Jobs from "../components/partner/Jobs";

class Partner extends Component {

    menuClickHandler = () => {
        this.changeMenuState(!this.state.menuclosed);
    };

    changeMenuState(state) {
        this.setState({menuclosed: !state})
    }


    login() {
        let email = this.state.txtEmailValue;
        let pass = this.state.txtPassValue;

        APICaller.login(email, pass, (res) => {
            if (res === '1,1') {
                this.setState({loggedIn: true, admin: true, error: false});
            } else if (res === '1,0') {
                this.setState({loggedIn: true, admin: false, error: false});
            } else {
                this.setState({error: true, errorText: "A megadott email - jelszó páros hibás."});
                setTimeout(() => {
                    this.setState({error: false, errorText: ""});
                }, 1500)
            }
        })
    }

    updateUserInfo() {
        APICaller.checkLoginState(res => {
            if (res === '1,1') {
                this.setState({
                    loggedIn: true,
                    admin: true,
                    error: false,
                    selectedMenu: 1,
                });
            } else if (res === '1,0') {
                this.setState({
                    loggedIn: true,
                    admin: false,
                    error: false,
                    selectedMenu: 2,
                });
            }
        });
        //TODO
        return null;
        /*
        return {
            email: "1@asd.com",
            admin: true,
        };
         */
    }

    constructor(props) {
        super(props);
        this.state = {
            loggedIn: false,
            menuclosed: false,
            admin: false,
            selectedMenu: 2,
            txtEmailValue: '',
            txtPassValue: '',
            error: false,
            errorText: "",
            changePass: false,
            editPass: "",
        };
    }

    componentDidMount() {
        this.setState({
            menuclosed: false,
            error: false,
            errorText: "",
        });
        this.updateUserInfo();
    }

    menuItemSelected(element) {
        if (element === 4) {
            APICaller.logout();
            this.setState({loggedIn: false, admin: false});
            return;
        } else if (element === 5) { //jelszó változtatás
            this.setState({
                changePass: true,
            });
            return;
        }
        this.setState({selectedMenu: element});
    }

    saveNewPass() {
        let pass = this.state.editPass;
        APICaller.changePass(pass);
        this.setState({
            changePass: false,
            editPass: "",
        });
    }

    render() {
        return (
            <div className={"page"}>
                <Modal onClose={() => this.setState({changePass: false})} open={this.state.changePass}>
                    <div className={"changePassModal"}>
                        <div className={"changePassForm"}>
                            <div className={"centeredWrapper"}>
                                <TextField
                                    label="Új jelszó (min. 5 karakter)"
                                    type="password"
                                    error={this.state.editPass.length < 5}
                                    value={this.state.editPass}
                                    onChange={e => this.setState({editPass: e.target.value})}
                                    margin="normal"
                                    variant="outlined"
                                />
                                <Button variant="outlined" color="primary" onClick={() => this.saveNewPass()}>
                                    Elmentés
                                </Button>
                                <br/>
                                <Button variant="outlined" color="primary" onClick={() => this.setState({changePass: false})}>
                                    Mégsem
                                </Button>
                            </div>
                        </div>
                    </div>
                </Modal>
                <div className={"error" + (!this.state.error ? ' hidden' : '')}>{this.state.errorText}</div>
                {this.state.loggedIn &&
                <div className={"dashboard"}>
                    <div className={"menu" + (this.state.menuclosed === true ? ' closed' : '')}>
                        <div className={"menuCloseButton"} onClick={() => this.menuClickHandler}>
                            <HamburgerMenuIcon open menuStateChanged={this.changeMenuState.bind(this)}/>
                        </div>
                        {this.state.admin &&
                        <div className={"menuElement"} onClick={() => {
                            this.menuItemSelected(1)
                        }}>
                            {(this.state.menuclosed ? 'H' : 'Hozzáférések')}
                        </div>
                        }
                        <div className={"menuElement"} onClick={() => {
                            this.menuItemSelected(2)
                        }}>
                            {(this.state.menuclosed ? 'F' : 'Megosztott fájlok')}
                        </div>
                        {this.state.admin &&
                        <div className={"menuElement"} onClick={() => {
                            this.menuItemSelected(3)
                        }}>
                            {(this.state.menuclosed ? 'B' : 'Blogcikkek')}
                        </div>
                        }
                        {this.state.admin &&
                        <div className={"menuElement"} onClick={() => {
                            this.menuItemSelected(6)
                        }}>
                            {(this.state.menuclosed ? 'P' : 'Nyitott pozíciók')}
                        </div>
                        }
                        <div className={"menuElement"} onClick={() => {
                            this.menuItemSelected(5)
                        }}>
                            {(this.state.menuclosed ? 'J' : 'Jelszó megváltoztatása')}
                        </div>


                        <div className={"menuElement logout"} onClick={() => {
                            this.menuItemSelected(4)
                        }}>
                            {(this.state.menuclosed ? 'K' : 'Kijelentkezés')}
                        </div>
                    </div>
                    <div className={"selectedMenuItemContainer"}>
                        <div className={"selectedMenuItemTitle"}>{this.getSelectedMenuTitle()}</div>
                        <div className={"selectedMenuItemContent"}>{
                            this.getSelectedMenuContainer()
                        }</div>
                    </div>
                </div>}

                {!this.state.loggedIn &&
                <div className={"loginpage"}>
                    <div className={"loginform"}>
                        <h3>Bejelentkezés</h3>
                        <TextField
                            id="txtEmail"
                            label="E-mail"
                            type="email"
                            name="email"
                            value={this.state.txtEmailValue}
                            onChange={e => this.setState({txtEmailValue: e.target.value})}
                            autoComplete="email"
                            margin="normal"
                            variant="outlined"
                        />
                        <TextField
                            id="txtPassword"
                            label="Jelszó"
                            type="password"
                            name="password"
                            value={this.state.txtPassValue}
                            onChange={e => this.setState({txtPassValue: e.target.value})}
                            autoComplete="password"
                            margin="normal"
                            variant="outlined"
                        />
                        <div className={"button"} onClick={() => this.login()}>Belépés</div>
                    </div>
                </div>
                }

            </div>
        );
    }

    getSelectedMenuContainer() {
        let menuContainers = [
            (<Users/>),
            (<Files admin={this.state.admin}/>),
            (<Blogs/>),
            (<></>),
            (<></>),
            (<Jobs/>),
        ];
        return menuContainers[this.state.selectedMenu - 1];
    }

    getSelectedMenuTitle() {
        let menuTitles = ['Hozzáférések', 'Megosztott fájlok', 'Blogs'];
        return menuTitles[this.state.selectedMenu - 1];
    }
}

export default Partner;
