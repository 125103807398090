import React, {Component} from 'react';
import './ApplyButton.css'
import {Modal} from "@material-ui/core";
import TextField from "@material-ui/core/TextField";
import {validate} from "email-validator";
import APICaller from "../../backend/APICaller";
import Button from "@material-ui/core/Button";

class ApplyButton extends Component {
  fileUpload;

  state: {
    open: boolean,
    editFirstName: string,
    editLastName: string,
    editEmail: string,
    editTel: string,
    editMsg: string,
    tried: boolean,

    cvFileName: string,
    title: string
  } = {
    open: false,
    editFirstName: "",
    editLastName: "",
    editEmail: "",
    editTel: "",
    editMsg: "",
    tried: false,

    cvFileName: "",
    title: ""
  }

  componentDidUpdate(prevProps: Readonly<P>, prevState: Readonly<S>, snapshot: SS) {
    if (this.state.title !== this.props.title)
      this.setState({title: this.props.title});
  }

  componentDidMount() {
    this.setState({title: this.props.title});
  }

  uploadFile() {
    let file = this.fileUpload.files[0];
    if (file)
      APICaller.uploadCV(file, (res) => {
        this.setState({cvFileName: APICaller.baseURL + '/uploads/cv/' + res});
      });
  }

  send() {
    this.setState({tried: true});
    if (this.checkFirstName() || this.checkLastName() || !validate(this.state.editEmail) || this.checkTel())
      return;
    if (this.state.cvFileName.length < 5)
      return;

    APICaller.sendCV(
      this.state.editFirstName,
      this.state.editLastName,
      this.state.editEmail,
      this.state.editTel,
      this.state.cvFileName,
      this.state.title,
      (res) => {
        if (res === "1") {
          alert('Jelentkezésedet megkaptuk! Hamarosan felvesszük veled a kapcsolatot!');
        } else {
          alert('Hiba történt a jelentkezés leadásakor.');
        }
      }
    );

    this.setState({
      editFirstName: '',
      editLastName: '',
      editEmail: '',
      editTel: '',
      editMsg: '',
      cvFileName: "",
      open: false,
      tried: false,
    })
  }

  checkFirstName() {
    return this.state.editFirstName.length <= 3;
  }

  checkLastName() {
    return this.state.editLastName.length <= 3;
  }

  checkTel() {
    return this.state.editTel.length < 7;
  }

  render() {
    return (
      <div>
        <Modal onClose={() => this.setState({open: false})} open={this.state.open}>
          <div className={"contactFormModal"}>
            <div className={"contactForm"}>
              <h3>Álláshirdetésre a lenti űrlap kitöltésével tudsz jelentkezni.</h3>
              <TextField
                label="Vezetéknév"
                type="lastname"
                error={this.state.tried && this.checkLastName()}
                value={this.state.editLastName}
                onChange={e => this.setState({editLastName: e.target.value})}
                margin="normal"
                variant="outlined"
              />
              <TextField
                label="Keresztnév"
                type="firstname"
                error={this.state.tried && this.checkFirstName()}
                value={this.state.editFirstName}
                onChange={e => this.setState({editFirstName: e.target.value})}
                margin="normal"
                variant="outlined"
              />
              <TextField
                label="E-mail cím"
                type="e-mail"
                error={this.state.tried && !validate(this.state.editEmail)}
                value={this.state.editEmail}
                onChange={e => this.setState({editEmail: e.target.value})}
                margin="normal"
                variant="outlined"
              />
              <TextField
                label="Telefonszám"
                type="tel"
                error={this.state.tried && this.checkTel()}
                value={this.state.editTel}
                onChange={e => this.setState({editTel: e.target.value})}
                margin="normal"
                variant="outlined"
              />
              {/*<TextField*/}
              {/*  label="További üzenet"*/}
              {/*  type="text"*/}
              {/*  multiline*/}
              {/*  rows={5}*/}
              {/*  value={this.state.editMsg}*/}
              {/*  onChange={e => this.setState({editMsg: e.target.value})}*/}
              {/*  margin="normal"*/}
              {/*  variant="outlined"*/}
              {/*/>*/}
              <label>Önéletrajz</label>
              <input ref={(ref) => this.fileUpload = ref}
                     onChange={() => this.uploadFile()} type={"file"}
                     accept={"application/pdf"}
                     name={"Önéletrajz"}/>
              <div className={"centeredWrapper"}>
                <Button variant="outlined" color="primary" onClick={() => this.send()}>
                  Jelentkezem
                </Button>
                <br/>
                <Button variant="outlined" color="primary" onClick={() => this.setState({open: false})}>
                  Mégsem
                </Button>
              </div>
            </div>
          </div>
        </Modal>
        <div onClick={() => this.setState({open: true})} className={"button"}>
          {this.props.text ? this.props.text : 'Jelentkezem'}
        </div>
      </div>
    );
  }
}

export default ApplyButton;
