import React, {Component} from 'react';
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import SwipeableViews from 'react-swipeable-views';
import TabContainer from "react-bootstrap/TabContainer";
import {isMobile} from "react-device-detect";
import '../css/Kolcsonok.css';
import SzemelyiKolcson from "../components/kolcsonok/SzemelyiKolcson";
import AdossagRendezes from "../components/kolcsonok/AdossagRendezes";

class Kolcsonok extends Component {
    state = {
        value: 0,
    };

    handleChange(newValue) {
        this.setState({value: newValue});
    }

    render() {
        return (
            <div className={"page"}>
                <AppBar position="static" color="default">
                    <Tabs
                        value={this.state.value}
                        onChange={(e, i) => this.handleChange(i)}
                        indicatorColor="primary"
                        textColor="primary"
                        variant={isMobile ? 'scrollable' : 'standard'}
                        scrollButtons="auto"
                        centered={!isMobile}
                    >
                        <Tab label="Személyi kölcsön"/>
                        <Tab label="Adósságrendezés"/>
                    </Tabs>
                </AppBar>
                <SwipeableViews
                    index={this.state.value}
                    onChangeIndex={(e, i) => this.handleChange(i)}
                >
                    <TabContainer><SzemelyiKolcson/></TabContainer>
                    <TabContainer><AdossagRendezes/></TabContainer>
                </SwipeableViews>
            </div>
        );
    }
}

export default Kolcsonok;
