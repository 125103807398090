import React, {Component} from 'react';
import '../css/Kapcsolat.css';
import TeamMember from "../components/team-member/TeamMember";
import member1 from '../assets/teammembers/member1.jpg';
import member2 from '../assets/teammembers/member2.jpg';
import member3 from '../assets/teammembers/member3.jpg';
import member4 from '../assets/teammembers/member4.jpg';
import member5 from '../assets/teammembers/member5.jpg';
import teamImage from '../assets/teammembers/team.jpg';
import ContactButton from "../components/contactbutton/ContactButton";
import imageCompanyBuilding from '../assets/company_building.jpg';
import image3 from '../assets/kapcsolat.jpg';
import team from '../assets/teamst.jpg';
import {Parallax} from 'react-parallax';

const insideStyles = {
  background: "white",
  padding: 20,
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%,-50%)"
};

class Kapcsolat extends Component {

  render() {
    return (
      <div className={"page"}>
        <Parallax
          bgImage={imageCompanyBuilding}
          strength={200}
          renderLayer={percentage => (
            <div>
              <div
                style={{
                  position: "absolute",
                  background: `rgba(255, 125, 0, ${percentage * 1})`,
                  left: "50%",
                  top: "50%",
                  borderRadius: "50%",
                  transform: "translate(-50%,-50%)",
                  width: percentage * 600,
                  height: percentage * 600,
                }}
              />
            </div>
          )}
        >
          <div style={{height: 600}}>
            <div style={insideStyles}><h2>Rólunk</h2></div>
          </div>
        </Parallax>

        <div className={"parallaxDiv"}>
          <p>
            A Credithome négy cégből álló pénzügyi holding. Tíz éve dolgozunk a pénzügyi szolgáltatások értékesítésének
            piacán.Ez idő alatt a hitelkártyák és személyi hitelek szakértői lettünk, ügyfeleinknek segítünk a lehető
            legtesthezállóbb megoldásokat megtalálni, melyek az adott élethelyzetükben hasznos segítséget nyújtanak
            számukra.<br/><br/>
            Partnereink a legmegbízhatóbb, leginnovatívabb és leginkább ügyfélközpontú pénzintézetek.
            Ügyfeleinkhez a legkülönbözőbb csatornákon jutunk el, így rajtunk keresztül bárki hozzájuthat a számára
            érdekes szolgáltatáshoz és ehhez nem szükséges személyesen megjelenni egy bankfiókban. Helyszíni
            értékesítőink a budapesti plázákban, a Liszt Ferenc Nemzetközi Repülőtéren és a legnépszerűbb rendezvényeken
            és kiállításokon várják az érdeklődőket. Mobilbankár csapatunk az ország bármelyik pontjára elér és
            alkalmazkodva az ügyfél igényekhez akár munkahelyen, akár az otthon kényelmében, vagy a kedvenc kávézóban is
            rendelkezésre áll.<br/><br/>
            A call centerünkben dolgozó kollégáink maximális szakértelemmel és empátiával szólítják
            meg ügyfeleinket, segítségükkel lehetőség van a teljes hitelfelvételt online intézni. Központi irodánk
            teljes személyzete az ügyletmenet leghatékonyabb lebonyolításán dolgozik, ügyfeleink és értékesítő
            kollégáink megelégedésére.<br/>
            Hitvallásunk: Egy vállalat pont annyira lehet innovatív, amennyire
            alkalmazottainak a munkahelyen kívül stabil az életük.
          </p>
          <div className={"centeredWrapper"}>
            <ContactButton text={"Érdekel"}/>
          </div>
        </div>
        <Parallax bgImage={image3} blur={{min: -1, max: 3}}>
          <div style={{height: 400}}>
            <div style={insideStyles}><h2>Elérhetőségek</h2></div>
          </div>
        </Parallax>
        <div className={"parallaxDiv"}>
          <div className={"Kapcsolat-infos"}>
            <p>
              Cím: CreditHome, HU-1042 Budapest, Árpád út 67. 2/1.<br/>
              Iroda nyitvatartás: Hétfő - Péntek 09:00 - 16:30-ig
              <br/>
              <br/>
              E-mail: <a href={"mailto:service@credithome.hu"}>karrier@credithome.hu</a><br/>
              Telefonszám: <a href={"tel:+3617982945"}>+36 1 798 2945</a><br/>
              Facebook oldalunk: <a
              href={"https://www.facebook.com/credithome.hu/"}>@credithome.hu</a><br/>
            </p>
          </div>
        </div>
        <Parallax bgImage={team} strength={-150}>
          <div style={{height: 500}}>
            <div style={insideStyles}><h2>Csapat</h2></div>
          </div>
        </Parallax>
        <div className={"parallaxDiv"}>
          <div className={"clickEffect"}/>
          <div className={"Kapcsolat-team"}>
            <TeamMember name={"Blazsó Gábor"} post={"Cégtulajdonos / Ügyvezető Igazgató"}
                        image={member1}
                        text={""}/>
            <TeamMember name={"Csiszér Péter"} post={"Társtulajdonos"} image={member3} text={""}/>
            <TeamMember name={"Glöckner Jakab"} post={"Cégtulajdonos / Ügyvezető Igazgató"} image={member2}
                        text={""}/>
            <TeamMember anim name={"Nyíri Krisztián"} post={"Telesales specialista"} image={member4}
                        text={""}/>
            <TeamMember name={"Dén Viktória és Oláh Cintia"} post={"Értékesítő csapat tagjai"}
                        image={member5}
                        text={""}/>
            <TeamMember name={"Csapat"} post={""} image={teamImage} text={""}/>
            <TeamMember name={"Csatlakozz hozzánk!"} post={""} text={""} new/>
          </div>
        </div>
      </div>
    );
  }
}

export default Kapcsolat;
