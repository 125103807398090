import React, {Component} from 'react';
import ExpansionPanel from "@material-ui/core/ExpansionPanel";
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails";
import './Eletbiztositas.css';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ContactButton from "../contactbutton/ContactButton";
import {Typography} from "@material-ui/core";

class Eletbiztositas extends Component {
    state = {
        expanded: '',
    };

    questions = [];

    handleExpansionChange(panel, isExpanded) {
        this.setState({expanded: isExpanded ? panel : ''});
    };

    render() {
        return (
            <div className={"wrapper"}>
                <h3>Életbiztosítás</h3>
                <h4>Biztonságban szeretném tudni magam</h4>
                <div>
                    <Typography>
                        Sokan szeretnék megvalósítani vágyaikat. Legyen szó az otthon felújításról, egy élmény dús
                        nyaralásról, vagy az öregedő autó cseréjéről.
                    </Typography>
                    <Typography>
                        Ügyfeleink jelentős része ezeket az álmokat meg tudja valósítani pénzügyi segítségünk
                        igénybevétele után.
                    </Typography>
                    <Typography>
                        Azonban előfordulhat egy baleset, betegség, műtét vagy egy elhúzódó gyógykezelés és a család
                        költségvetése felborulhat.
                    </Typography>
                    <Typography>
                        Ön eldönti, hogy mire szeretne költeni. Szakembereink segítenek megválasztani a leginkább
                        megfelelő biztosítási megoldást, hogy baj esetén Ön csak a gyógyulásával foglalkozhasson.
                    </Typography>
                </div>
                <br/>
                <h3>Szakember</h3>
                <h4>Szakember segítségére van szükségem, kérem, vegyék fel velem a kapcsolatot!</h4>
                <br/>
                <div className={"centeredWrapper"}><ContactButton text={"Visszahívást kérek!"}/></div>
                <br/>
                <h3>Előnyök</h3>
                <h4>Személy biztosítás előnyei</h4>
                <div>
                    <ul>
                        <li><strong>Védelem a váratlan helyzetekben</strong></li>
                        <li><strong>Gyors</strong> és megbízható <strong>segítség, baleset, betegség,</strong> vagy
                            egy <strong>elhalálozás esetén, történjen az a világban bárhol</strong></li>
                        <li><strong>Személyre szabható</strong>an állíthatja össze, hogy milyen helyzetben nyújtson
                            segítséget <strong>biztosítás</strong>a. Így <strong>csak azért fizet, amire tényleg
                                szüksége van</strong></li>
                        <li><strong>Felhasználható táppénz kiegészítésnek</strong>, így a családi költségvetés megmarad
                        </li>
                        <li><strong>Kritikus betegségek esetén előrehozott szolgáltatás</strong>t vehet igénybe</li>
                        <li><strong>Váratlan tragédia esetén a fennálló hitelét is kifizettetheti</strong>, így a
                            szerettei nem örökölnek adósságot.
                        </li>
                        <li><strong>Bónusz</strong>ként ha nem vette igénybe szolgáltatásunkat, akkor a <strong>befizetései
                            20%-át is visszaadhatjuk</strong> szerződése lejártakor
                        </li>
                        <li><strong>Várakozási idő alatt is igénybe vehető szolgáltatások</strong></li>
                        <li><strong>Magyarországon belül teljes orvosi ellátás</strong>t tudunk kínálni, így <strong>nem
                            kell idő és külön pénzt költeni, arra hogy megfelelő orvosi ellátást találjon</strong></li>
                        <li><strong>Saját Gyógyulására koncentrálhat</strong></li>
                        <li><strong>24 órában elérhető egészségügyi tanácsadás</strong></li>
                        <li><strong>Nincs várólista és hálapénz</strong> (orvosi szolgáltatás igénylése esetén)</li>
                        <li><strong>Előre tervezhető szűrű vizsgálatok</strong>, így egy betegséget kialakulásakor már
                            kezelni tudnak.
                        </li>
                        <li><strong>Könnyebb, egyszerűbb,biztosabb és olcsóbb gyógyulás</strong></li>
                    </ul>
                </div>
                {
                    this.questions.length > 0 &&
                    <h3>Gyakran ismételt kérdések</h3>
                }
                {
                    this.questions.map((value, index) => {
                        return (
                            <ExpansionPanel key={index} expanded={this.state.expanded === 'panel' + index}
                                            onChange={(event, expanded) => this.handleExpansionChange('panel' + index, expanded)}>
                                <ExpansionPanelSummary
                                    expandIcon={<ExpandMoreIcon/>}
                                >
                                    <p className={"EletbiztositasExpansionHeading"}>{value.title}</p>
                                </ExpansionPanelSummary>
                                <ExpansionPanelDetails>
                                    {value.ans}
                                </ExpansionPanelDetails>
                            </ExpansionPanel>
                        );
                    })
                }
                <br/>
                <br/>
                <div className={"centeredWrapper"}><ContactButton text={"Visszahívást kérek!"}/></div>
                <br/>
            </div>
        );
    }
}

export default Eletbiztositas;
