import React, {Component} from "react";
import './Files.css'
import APICaller from "../../backend/APICaller";
import Svg018RubbishBin from "../../Icons/icons/018RubbishBin";
import download_icon from '../../assets/icons/download.png';
import {Button, Modal} from "@material-ui/core";
import TextField from "@material-ui/core/TextField";

class Files extends Component {

    styles = {
        btn: {
            margin: "0 10px 0 10px",
        },
    };

    fileUpload;

    constructor(props) {
        super(props);
        this.state = {
            files: [],
            folders: [],
            modalOpen: false,
            editFolderName: "",
            selectedFolder: -1,
        }
    }

    getFiles() {
        APICaller.getAllFiles(res => {
            if (res)
                this.setState({files: res});
        })
    }

    getFolders() {
        APICaller.getAllFolders(res => {
            if (res)
                this.setState({folders: res});
        })
    }

    componentDidMount() {
        this.getFiles();
        this.getFolders();
    }

    uploadFile() {
        let file = this.fileUpload.files[0];
        if (file)
            APICaller.sendFile(this.state.selectedFolder, file, () => {
                this.getFiles();
            });
    }

    deleteItem(id) {
        APICaller.deleteFile(id);
        this.setState({files: this.state.files.filter(f => f.ID !== id)})
    }

    deleteFolder(e, id) {
        e.stopPropagation();
        APICaller.deleteFolder(id);
        this.setState({folders: this.state.folders.filter(f => f.ID !== id)})
    }

    saveNewFolder() {
        APICaller.saveFolder(this.state.editFolderName, res => {
            let folders = this.state.folders;
            folders.push({ID: res, foldername: this.state.editFolderName});
            this.setState({
                folders: folders,
                modalOpen: false,
                editFolderName: "",
            });
        });
    }

    render() {
        return (
            <div className={"filesContainer"}>
                <Modal onClose={() => this.setState({modalOpen: false})} open={this.state.modalOpen}>
                    <div className={"fileEditingModal"}>
                        <div className={"fileEditingModalContent"}>
                            <TextField
                                label="Új mappa neve"
                                type="text"
                                value={this.state.editFolderName}
                                onChange={e => this.setState({editFolderName: e.target.value})}
                                margin="normal"
                                variant="outlined"
                            />
                            <div className={"modalactions"}>
                                <Button onClick={() => this.saveNewFolder()} variant="outlined" size="small"
                                        color="primary">
                                    Mentés
                                </Button>
                                <Button onClick={() => this.setState({modalOpen: false})} variant="outlined"
                                        size="small"
                                        color="primary">
                                    Mégsem
                                </Button>
                            </div>
                        </div>
                    </div>
                </Modal>
                <div>
                    {this.state.selectedFolder !== -1 &&
                    <Button style={this.styles.btn} onClick={() => this.setState({selectedFolder: -1})}
                            variant="outlined" size="small"
                            color="primary">
                        Vissza
                    </Button>
                    }
                    {this.props.admin && this.state.selectedFolder !== -1 &&
                    <input ref={(ref) => this.fileUpload = ref} onChange={() => this.uploadFile()} type={"file"}
                           name={"Dokumentum"}/>
                    }
                    {this.state.selectedFolder === -1 && this.props.admin &&
                    <Button style={this.styles.btn} onClick={() => this.setState({modalOpen: true})} variant="outlined"
                            size="small"
                            color="primary">
                        Új mappa
                    </Button>
                    }
                </div>
                <div className={"file"}>
                    <div className={"filename"}><b>{this.state.selectedFolder !== -1 ? "Fájl neve" : "Mappa neve"}</b>
                    </div>
                    <div className={"actions"}>
                        <b>Műveletek</b>
                    </div>
                </div>
                {this.state.selectedFolder === -1 && this.state.folders && this.state.folders.map && this.state.folders.map(value =>
                    <div key={"folder_" + value.ID}
                         onClick={() => this.setState({selectedFolder: value.ID})}
                         className={"file folder"}>
                        <div className={"filename"}>{value.foldername}</div>
                        <div className={"actions"}>
                            {this.props.admin &&
                            <div onClick={(e) => this.deleteFolder(e, value.ID)} className={"delete"}><Svg018RubbishBin
                                width={20}
                                height={20}
                                viewBox={"0 0 450 450"}/>
                            </div>
                            }
                        </div>
                    </div>
                )
                }
                {this.state.selectedFolder !== -1 &&
                this.state.files.filter(value => value.folderID === this.state.selectedFolder).map(value =>
                    <div key={value.ID} className={"file"}>
                        <div className={"filename"}>{value.filename}</div>
                        <div className={"actions"}>
                            <a href={APICaller.baseURL + "/uploads/" + value.filename} target={"_blank"}
                               className={"download"}>
                                <img src={download_icon} alt={'download'} width={20}/>
                            </a>
                            {this.props.admin &&
                            <div onClick={() => this.deleteItem(value.ID)} className={"delete"}><Svg018RubbishBin
                                width={20}
                                height={20}
                                viewBox={"0 0 450 450"}/>
                            </div>
                            }
                        </div>
                    </div>
                )}
            </div>
        );
    }
}

export default Files;