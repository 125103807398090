import React, {Component} from 'react';
import './navigation-logo.css';
import credithome_logo from '../../assets/logo_white.png';
import credithome_logo_dark from '../../assets/credithome_logo_2.png';

class HeaderLogo extends Component<{ dark: boolean }> {

  state: {
    dark: boolean,
  } = {
    dark: true,
  }

  loadProps() {
    this.setState({
      dark: this.props.dark
    });
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.state.dark !== this.props.dark)
      this.loadProps();
  }

  componentDidMount() {
    this.loadProps();
  }

  render() {
    return (
      <div className={"navigation-logo"}>
        {this.state.dark &&
        <img alt={"logo"} src={credithome_logo_dark}/>
        }
        {!this.state.dark &&
        <img alt={"logo"} src={credithome_logo}/>
        }
      </div>
    );
  }
}

export default HeaderLogo;
