import {withRouter} from "react-router";
import {Component} from "react";
import EventSystem from "./EventSystem";

class ScrollToTop extends Component {
    componentDidUpdate(prevProps, prevState, snapShot) {
        if (this.props.location !== prevProps.location) {
            try {
                window.scrollTo({top: 0, behavior: 'smooth'});
            } catch (e) {
                window.scrollTo(0, 0);
            }
            if (this.props.location.pathname.indexOf("/blog/") === 0) {
                EventSystem.publish("blogURLChanged", {});
            }
            EventSystem.publish("urlChanged", {});
        }
    }

    render() {
        return this.props.children;
    }
}

export default withRouter(ScrollToTop);