// noinspection DuplicatedCode

import React, {Component} from "react";
import './Blogs.css'
import {Editor} from "react-draft-wysiwyg";
import {EditorState, convertToRaw, ContentState} from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';

import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import {Button, Modal} from "@material-ui/core";
import TextField from "@material-ui/core/TextField";
import APICaller from "../../backend/APICaller";
import Svg029ForwardArrow from "../../Icons/icons/029ForwardArrow";
import Svg018RubbishBin from "../../Icons/icons/018RubbishBin";

export default class Jobs extends Component {

  fileUpload;

  constructor(props) {
    super(props);
    this.state = {
      editTitle: "",
      editBlogPost: EditorState.createEmpty(),
      editID: -1,
      editBlogBackground: '',
      modalOpen: false,
      newBlog: false,
      blogs: [],
    };
  }

  createNewJob() {
    this.setState({
      modalOpen: true,
      newBlog: true,
      editID: -1,
      editBlogBackground: '',
      editTitle: '',
      editBlogPost: EditorState.createWithContent(ContentState.createFromText("Elvárások\r\nElőnyt jelentő készségek\r\nRövid munkaköri leírás\r\nHirdetés részletei. Napi feladataid\r\nJuttatások\r\nExtrák"))
    });
  }

  uploadImageCallBack(file) {
    return new Promise(
      (resolve, reject) => {
        const xhr = new XMLHttpRequest();
        xhr.open('POST', APICaller.baseURL + '/files/upload_blog.php');
        xhr.withCredentials = true;

        const data = new FormData();
        data.append('document', file);
        xhr.send(data);

        xhr.addEventListener('load', () => {
          resolve({
            data: {
              link: APICaller.baseURL + '/uploads/blog/' + xhr.responseText
            }
          });
        });
        xhr.addEventListener('error', () => {
          reject(xhr.responseText);
        });
      }
    );
  }

  modifyJob(id) {
    let job = this.state.blogs.find(val => val.ID === id);

    const blocksFromHtml = htmlToDraft(job.content);
    const {contentBlocks, entityMap} = blocksFromHtml;
    const contentState = ContentState.createFromBlockArray(contentBlocks, entityMap);
    const editorState = EditorState.createWithContent(contentState);

    this.setState({
      modalOpen: true,
      editID: job.ID,
      editTitle: job.title,
      editBlogBackground: job.background_image,
      editBlogPost: editorState,
    });
  }

  deleteBlog(id) {
    let jobs = this.state.blogs;
    let blog = jobs.find(value => value.ID === id);

    if (blog.deleted === 1)
      return;

    APICaller.deleteBlog(id);
    blog.deleted = 1;
    this.setState({blogs: jobs});
  }

  uploadFile() {
    let file = this.fileUpload.files[0];
    if (file)
      APICaller.uploadBlogBackground(file, (res) => {
        console.log(res);
        this.setState({editBlogBackground: APICaller.baseURL + '/uploads/blog/' + res});
      });
  }

  save() {
    let html = draftToHtml(convertToRaw(this.state.editBlogPost.getCurrentContent()));

    let job = {
      ID: this.state.editID,
      title: this.state.editTitle,
      content: html,
      deleted: 0,
      background_image: this.state.editBlogBackground,
    };

    APICaller.saveJob(job, (res) => {
      if (res === '1') {
        APICaller.getAllJobs((res) => {
          this.setState({
            blogs: res,
            editID: -1,
            editTitle: '',
            editBlogPost: EditorState.createEmpty(),
            modalOpen: false,
          });
        });
      } else {
        window.alert('Hiba történt a pozíció mentése közben.');
      }
    });

  }

  componentDidMount() {
    APICaller.getAllJobs(res => {
      this.setState({blogs: res});
    });
  }

  render() {
    return (
      <div className={"blogsContainer"}>
        <Modal onClose={() => this.setState({modalOpen: false})} open={this.state.modalOpen}>
          <div className={"blogEditingModal"}>
            <div className={"blogEditingModalContent"}>
              <div className={"editBackground"}>
                Kép:
                {this.state.editBlogBackground && this.state.editBlogBackground.length > 0 &&
                <img alt={"hatterkepaj"} height={"75px"} src={this.state.editBlogBackground}/>
                }
                <input ref={(ref) => this.fileUpload = ref}
                       onChange={() => this.uploadFile()} type={"file"}
                       name={"Háttérkép"}/>
              </div>
              <TextField
                className={"txtBlogPost"}
                label="Pozíció neve"
                type="text"
                value={this.state.editTitle}
                onChange={e => this.setState({editTitle: e.target.value})}
                margin="normal"
                variant="outlined"
              />
              <Editor
                onEditorStateChange={editorState => this.setState({editBlogPost: editorState})}
                editorState={this.state.editBlogPost}
                wrapperClassName={"editorWrapper"}
                editorClassName={"editor"}
                toolbar={{
                  inline: {inDropdown: true},
                  list: {inDropdown: true},
                  textAlign: {inDropdown: true},
                  link: {inDropdown: true},
                  history: {inDropdown: true},
                  image: {
                    uploadCallback: this.uploadImageCallBack,
                    alt: {present: true, mandatory: true},
                    previewImage: true,
                    defaultSize: {
                      height: '400px',
                      width: 'auto',
                    },
                  },
                }}
              />

              <div className={"modalactions"}>
                <Button onClick={() => this.save()} variant="outlined" size="small"
                        color="primary">
                  Mentés
                </Button>
                <Button onClick={() => this.setState({modalOpen: false})} variant="outlined"
                        size="small"
                        color="primary">
                  Mégsem
                </Button>
              </div>
            </div>
          </div>
        </Modal>
        <Button onClick={() => this.createNewJob()} variant="outlined"
                size="small"
                color="primary">
          Új pozíció
        </Button>
        <div className={"oneBlog"}>
          <div className={"oneBlogState"}><b>Állapot</b></div>
          <div className={"oneBlogTitle"}><b>Pozíció, cím</b></div>
          <div className={"actions"}>
            <b>Műveletek</b>
          </div>
        </div>
        {this.state.blogs.length > 0 && this.state.blogs.map(val => {
          return (
            <div key={val.ID} className={"oneBlog"}>
              <div className={"oneBlogState"}>{val.deleted === 1 ? 'Inaktív' : 'Aktív'}</div>
              <div className={"oneBlogTitle"}>{val.title}</div>
              <div className={"actions"}>
                <div onClick={() => this.modifyJob(val.ID)} className={"modify"}>
                  <Svg029ForwardArrow
                    width={20} height={20}
                    viewBox={"0 0 450 450"}/>
                </div>
                <div onClick={() => this.deleteBlog(val.ID)}
                     className={"delete" + (val.deleted === 1 ? ' disabled' : '')}><Svg018RubbishBin
                  width={20}
                  height={20}
                  viewBox={"0 0 450 450"}/>
                </div>
              </div>
            </div>
          )
        })}
      </div>
    );
  }
}
