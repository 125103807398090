import React, {Component} from 'react';
import './Calculator.css';
import {Typography} from "@material-ui/core";
import InputRange from 'react-input-range';
import 'react-input-range/lib/css/index.css';
import ContactButton from "../contactbutton/ContactButton";


const formatter = new Intl.NumberFormat('hu-HU', {
    style: 'currency',
    currency: 'HUF',
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
});


class Calculator extends Component {
    state = {
        total: 1500000,
        months: 96,
        kamat: 10.99,
        havitorleszto: 45000,
        teljesVissza: 10,
    };

    handleMonthChange(months) {
        this.setState({months});
        this.calc(this.state.total, months);
    }

    handleTotalChange(total) {
        this.setState({total});
        this.calc(total, this.state.months);
    }

    calc(total, months) {
        let kamat = 0;
        if (total <= 3000000) {
            kamat = 10.99;
        } else if (total <= 8000000) {
            kamat = 8.49;
        } else if (total <= 10000000) {
            kamat = 7.49;
        }

        let r = kamat / 12 / 100;

        let havitorleszto = total / (1 / r - 1 / (r * Math.pow((1 + r), months)));
        let teljesVissza = havitorleszto * months;

        this.setState({kamat, havitorleszto, teljesVissza})
    }

    componentDidMount() {
        this.handleMonthChange(96);
        this.handleTotalChange(1500000);
    }

    render() {
        return (
            <div className={"calculator"}>
                <div className={"calc_bck1"}/>
                <div className={"calc_bck2"}/>
                <div className={"calc_bck3"}/>
                <h2>Kalkulátor</h2>
                {/*
                <Select
                    value={this.state.age}
                    onChange={(event) => this.setState({age: event.target.value})}
                    displayEmpty
                    name="age"
                >
                    <MenuItem value={10}>Személyi kölcsön</MenuItem>
                    <MenuItem value={20}>Személyi kölcsön 3M - 8M</MenuItem>
                    <MenuItem value={30}>Személyi kölcsön 8M - 10M</MenuItem>
                </Select>
                */}

                <Typography style={{marginBottom: '20px'}}>Mekkora összegre van szüksége?</Typography>
                <InputRange
                    formatLabel={value => formatter.format(value)}
                    maxValue={10000000}
                    step={10000}
                    minValue={1500000}
                    value={this.state.total}
                    onChange={value => this.handleTotalChange(value)}
                />
                <Typography style={{marginBottom: '20px', marginTop: '25px'}}>Milyen hosszú legyen a
                    futamidő?</Typography>
                <InputRange
                    formatLabel={value => value / 12 + " év"}
                    maxValue={96}
                    step={6}
                    minValue={36}
                    value={this.state.months}
                    onChange={months => this.handleMonthChange(months)}
                />
                <p>
                    Kamat: {this.state.kamat}%<br/>
                    Havi törlesztő részlet: {formatter.format(this.state.havitorleszto)}<br/>
                    Teljes visszafizetett összeg: {formatter.format(this.state.teljesVissza)}<br/>
                </p>
                <ContactButton text={"Érdekel"}/>

            </div>
        )
            ;
    }
}

export default Calculator;