import React, {Component} from 'react';
import ExpansionPanel from "@material-ui/core/ExpansionPanel";
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails";
import './Vagyonbiztositas.css';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ContactButton from "../contactbutton/ContactButton";
import {Typography} from "@material-ui/core";

class Vagyonbiztositas extends Component {
    state = {
        expanded: '',
    };

    questions = [];

    handleExpansionChange(panel, isExpanded) {
        this.setState({expanded: isExpanded ? panel : ''});
    };

    render() {
        return (
            <div className={"wrapper"}>
                <h3>Vagyonbiztosítás</h3>
                <h4>Biztonságban szeretném tudni vagyontárgyaimat</h4>
                <div>
                    <Typography>
                        Sokan vágyakoznak egy új autó, motor, vagy egy hajó után.
                    </Typography>
                    <Typography>
                        Ügyfeleink jelentős része ezeket a vágyakat váltja valóra pénzügyi segítségünk igénybevétele
                        után.
                    </Typography>
                    <Typography>
                        Azonban előfordulhat egy baleset, vagy egy lopás/rongálás és valljuk be az időjárás
                        szeszélyessége miatt a viharkárok sem ritkák.
                    </Typography>
                    <Typography>
                        Ön kiválasztja és megvásárolja álmai járművét, szakembereink segítenek megtalálni az Önnek
                        leginkább megfelelő biztosítási megoldást.
                    </Typography>
                </div>
                <br/>
                <h3>Szakember</h3>
                <h4>Szakember segítségére van szükségem, kérem, vegyék fel velem a kapcsolatot!</h4>
                <br/>
                <div className={"centeredWrapper"}><ContactButton text={"Visszahívást kérek!"}/></div>
                <br/>
                <h3>Casco</h3>
                <h4>Casco biztosítás előnyei</h4>
                <div>
                    <ul>
                        <li><strong>Teljes körű-&nbsp;</strong>vagy&nbsp;<strong>rész casco lehetőségek&nbsp;</strong>számos
                            variációs lehetőséggel
                        </li>
                        <li><strong>Rugalmas, ügyfélbarát kárrendezés</strong></li>
                        <li>Idősebb, kármentes autókra is köthető akár</li>
                        <li><strong>24 órás segítségnyújtás</strong></li>
                        <li><strong>Személyre szabható</strong> számos kiegészítőnek köszönhetően
                            <ul>
                                <li><strong> </strong><strong>Hibás tankolás biztosítás (ha nem előírt üzemanyagot
                                    tankolt véletlenül)</strong></li>
                                <li><strong> </strong><strong>Műszaki hiba assistance szolgáltatás</strong> <strong>(helyszíni
                                    javítás, autómentős szállítás, hazautazás megszervezése, továbbutazás megszervezése,
                                    bérautó szervezése, éjszakai szállás megszervezése)</strong></li>
                                <li>Új autó esetén, akár a vételár megtérítése (baleset vagy lopás esetén)</li>
                                <li>Avulás mentes térítés</li>
                                <li>Roncs tárolása, értékesítése</li>
                                <li>Vételár garancia (6 éves korú autóig a vásárlástól számított 60 napon belüli
                                    totálkár esetén, kifizeti a vételár és az EUROTAX érték közötti összeget)
                                </li>
                                <li>Balesetbiztosítás az autóban utazóknak</li>
                                <li>Poggyász kártérítés</li>
                                <li>Jogi segítségnyújtás</li>
                                <li>Sofőr szolgálat</li>
                                <li>Kölcsöngépjármű biztosítása</li>
                            </ul>
                        </li>
                    </ul>
                </div>
                {
                    this.questions.length > 0 &&
                    <h3>Gyakran ismételt kérdések</h3>
                }
                {
                    this.questions.map((value, index) => {
                        return (
                            <ExpansionPanel key={index} expanded={this.state.expanded === 'panel' + index}
                                            onChange={(event, expanded) => this.handleExpansionChange('panel' + index, expanded)}>
                                <ExpansionPanelSummary
                                    expandIcon={<ExpandMoreIcon/>}
                                >
                                    <p className={"VagyonbiztositasExpansionHeading"}>{value.title}</p>
                                </ExpansionPanelSummary>
                                <ExpansionPanelDetails>
                                    {value.ans}
                                </ExpansionPanelDetails>
                            </ExpansionPanel>
                        );
                    })
                }
                <br/>
                <br/>
                <div className={"centeredWrapper"}><ContactButton text={"Visszahívást kérek!"}/></div>
                <br/>
            </div>
        );
    }
}

export default Vagyonbiztositas;
