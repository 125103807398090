import React, {Component} from 'react';
import '../css/Adatkezeles.css';

class Adatkezeles extends Component {
    render() {
        return (
            <div className={"page"}>
                <article className={"adatkezelesWrapper"}>
                    <h1>Adatkezelési tájékoztató</h1>
                    <ol>
                        <li>
                            <b>Adatkezelő</b><br/>
                            A Credithome Cégcsoport (a továbbiakban Ügynök), melynek tagjai:
                            <ul>
                                <li>
                                    Pritex Pénzügyi Tanácsadó Korlátolt Felelősségű Társaság a Fővárosi Törvényszék
                                    Cégbírósága
                                    által Cg.
                                    01-09-162096 cégjegyzékszám alatt bejegyzett és nyilvántartott gazdasági társaság,
                                    székhelye: 1121.
                                    Budapest, Alkony u.26, adószáma: 10704200-2-43
                                </li>
                                <li>
                                    JPG Group Pénzügyi Tanácsadó Korlátolt Felelősségű Társaság a Fővárosi Törvényszék
                                    Cégbírósága által
                                    Cg. 13-09-165975 cégjegyzékszám alatt bejegyzett és nyilvántartott gazdasági
                                    társaság, székhelye: 2120. Dunakeszi, Pihenő u.22, adószáma: 24120377-1-13
                                </li>
                                <li>
                                    JPG Agent Pénzügyi Szolgáltató Korlátolt Felelősségű Társaság a Budapest Környéki
                                    Törvényszék
                                    Cégbírósága által Cg. 01-09-197118 cégjegyzékszám alatt bejegyzett és nyilvántartott
                                    gazdasági
                                    társaság, székhelye: 1031. Budapest, Vízimolnár u. 15. 4/13, adószáma 25055337-1-41
                                </li>
                                <li>
                                    Credithome Mortgage Korlátolt Felelősségű Társaság a Budapest Környéki Törvényszék
                                    Cégbírósága
                                    által Cg. 13-09-092572 cégjegyzékszám alatt bejegyzett gazdasági társaság,
                                    székhelye: 2120. Dunakeszi,
                                    Pihenő u.22, adószáma: 26292320-1-13.
                                </li>
                                Az Ügynök adatkezelési gyakorlata az Európai Unió 2016/679 számú általános adatvédelmi
                                rendeletén (
                                GDPR) , valamint az információs önrendelkezési jogról és az információszabadságról szóló
                                2011. évi CXII.
                                törvényen, valamint a hitelintézetekről és pénzügyi vállalkozásokról szóló 2013. évi
                                CCXXXVII.
                                törvényen(Hpt.) alapszik.
                            </ul>
                        </li>
                        <li>
                            <b>Az adatkezelés, célja, jogalapja, a kezelt adatok köre, az adatkezelés időtartama,
                                adatkezelés módja.</b><br/>
                            <ol>
                                <li>
                                    az Ügyfél számára pénzügyi szolgáltatás közvetítési szolgáltatás nyújtása az
                                    Üzletszabályzat
                                    szerint, ajánlatok készítése a GDPR. 6. cikk (1 bekezdés b.) pontja- a szerződés
                                    teljesítéséhez
                                    szükséges adatkezelés – ügyfél neve, születési neve, lakcíme, anyja neve, születési
                                    helye, e-mail címe,
                                    telefonszáma, – ügyfél által önkéntesen megadott, az ügyfél igényének felméréséhez
                                    és a
                                    megfelelő
                                    ajánlat készítéséhez szükséges adatok (Pl: igénylendő hitel összege, önerő összege,
                                    eltartottak száma,
                                    jövedelemre vonatkozó adatok stb) az Ügyfél és az Ügynök között az üzletszabályzat
                                    alapján létrejött
                                    szerződéstől számított 6 hónap.
                                </li>
                                <li>
                                    a Hpt. 69.§ (5) bekezdésében rögzített nyilvántartási és irat őrzési kötelezettség
                                    teljesítése a
                                    Hpt. 69.§ (5) bekezdésben rögzített nyilvántartási és irat őrzési kötelezettség
                                    Ügyfél
                                    neve, a
                                    közvetített szerződés feleinek a neve, megkötésének ideje, tárgya, lényeges
                                    feltételei,
                                    közvetített
                                    szerződés az Ügyfél és az Ügynök között az üzletszabályzat alapján létrejött
                                    szerződéstől számított 6
                                    hónap.
                                </li>
                                <li>
                                    Az Ügynök jogszerű, a pénzügyi intézmény megbízói felé jogszerű polgári jogi
                                    igényének
                                    érvényesítése a GDPR 6. cikk (1) bekezdés f.) pontja az adatkezelő jogos érdekének
                                    érvényesítése Ügyfél
                                    neve, a közvetített szerződés feleinek a neve, megkötésének ideje, tárgya, lényeges
                                    feltételei,
                                    közvetített szerződés a közvetített szerződés pénzügyi intézményhez történő
                                    benyújtásától számított 6
                                    hónap.
                                </li>
                                <li>
                                    az adatok piackutatás, piaci elemzések,
                                    statisztikák készítésére történő felhasználása, a GDPR. 6. cikk (1 bekezdés a.)
                                    pontja –
                                    az ügyfél
                                    önkéntes hozzájár ulása Ügyfél neve, telefonszáma, email címe, a közvetített
                                    szerződés
                                    tárgya és összege
                                    az adatkezelés időtartama a hozzájárulás visszavonásáig, de legfeljebb a
                                    hozzájárulás
                                    megadásától
                                    számított 6 hónap tart.
                                </li>
                            </ol>
                            Az adatrögzítés akként történik, hogy az Ügynök Közreműködője (, alkalmazott, közvetítői
                            alvállalkozó)
                            rögzíti az Ügynök központi elektronikus adatbázisába az adatokat, illetve a szolgáltatás
                            nyújtásához
                            szükséges papír alapú dokumentáción is feltünteti a szükséges adatokat. Az ügyfél személyes
                            adataihoz az
                            Ügynökön kívül az Ügynök azon közreműködője férhet hozzá, akinél az ügyfél a szolgáltatást
                            igénybe veszi
                            és aki a pénzügyi szolgáltatás közvetítési tevékenységben részt vesz. Az Ügynök az ügyfél
                            adatait
                            továbbítja a pénzügyi intézmény részére.
                        </li>
                        <li>
                            <b>Az Ügyfél jogai</b><br/>
                            Az Ügyfél bármikor jogosult tájékoztatást kérni az Ügynök által kezelt, rá vonatkozó
                            személyes
                            adatokról, kérheti a hibás adatok helyesbítését, változás esetén azok módosítását. Az Ügyfél
                            az Ügynök
                            1135. Budapest, Jász u.99. szám alatti levelezési címére megküldött levélben vagy a
                            service@credithome.hu
                            email címre megküldött levélben bármikor megtilthatja személyes adatainak további
                            felhasználását olyan
                            célra, amelynél az adatkezelés jogalapja az önkéntes hozzájárulása volt (direkt marketing
                            célú
                            felhasználás, piackutatás, piaci elemzések, statisztikák készítése).
                        </li>
                        <li>
                            <b>Jogorvoslati lehetőségek</b><br/>
                            Az ügyfél bármilyen adatkezeléssel kapcsolatos kérdéssel, észrevétellel, panasszal
                            megkeresheti az
                            Ügynököt az service@credithome.hu email címre küldött vagy a 1135. Budapest, Jász u.99. szám
                            alatti
                            székhelyre küldött levéllel. Az ügyfél az adatai kezelésével kapcsolatos panasz esetén
                            beadvánnyal
                            fordulhat a Nemzeti Adatvédelmi és Információszabadság Hatósághoz, vagy pert indíthat a
                            lakóhelye
                            (tartózkodási helye) szerint illetékes törvényszéken.
                        </li>
                    </ol>
                </article>
            </div>
        );
    }
}

export default Adatkezeles;
