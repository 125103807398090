import React, {Component} from 'react';
import Calculator from "../components/calculator/Calculator";
import '../css/CalculatorPage.css';

class CalculatorPage extends Component {
    render() {
        return (
            <div className={"page"}>
                <div className={"wrapper"}>
                    <div className={"calc_bck"}/>
                    <p>
                        A kalkulátorok csak tájékoztató jellegűek!<br/>
                        A kalkulátorok nem veszi figyelembe a 32/2014. (IX. 10.) MNB rendeletben foglalt
                        jövedelemarányos
                        törlesztőrészlet számítást.<br/>
                        A tájékoztatás nem teljes körű és nem minősül ajánlattételnek, optimális esetet mutat, a kamat
                        nagysága függ a felvenni kívánt összeg nagyságától és az igénylő egyedi paramétereitől.<br/>
                        Pontos és hiteles ajánlatot a regisztráció után, a megadott személyes paraméterek ismeretében
                        tudunk
                        adni.<br/>
                    </p>
                    <Calculator/>
                </div>
            </div>
        );
    }
}

export default CalculatorPage;