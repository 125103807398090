import React, {Component} from 'react'

class ErrorPage extends Component{
    render() {
        return (
          <div className={"page"}>
              A kért oldal nem található! :(
          </div>
        );
    }
}
export default ErrorPage;