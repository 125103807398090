import React, {Component} from 'react';
// import icon_jelzalog from "../assets/icons/jelzalog_hitel.png"
import "react-alice-carousel/lib/alice-carousel.css";
import {NavLink as NavLink2} from "react-router-dom";
import styled, {css} from "styled-components";
import blueBG from '../assets/home/blue_bg.svg';
import blue_bg_mobile from '../assets/home/blue_bg_mobile.svg';
import top_left_orange from '../assets/home/top_left_orange.svg';
import orangeGear from '../assets/home/orangeGear.svg';
import rightPaca from '../assets/home/rightPaca.svg';
import rightPaca2 from '../assets/home/rightPaca2.svg';
import team_selfie from '../assets/home/team_selfie.jpg';
import smallPacaBlue from '../assets/home/smallPacaBlue.svg';
import smallPacaWhite from '../assets/home/smallPacaWhite.svg';
import smallPacaGreen from '../assets/home/smallPacaGreen.svg';
import leftOrangePaca from '../assets/home/leftPaca.svg';
import teamPaca2 from '../assets/home/teamPaca2.svg';
import counterPaca from '../assets/home/counterPaca.svg';
import waving_flags_africa from '../assets/home/waving_flags_africa.svg';
import pacaOrange from '../assets/home/pacaOrange.svg';
import {withRouter} from "react-router-dom";
import {
  BiNetworkChart,
  HiOutlineDocumentText,
  MdOutlineSettingsInputAntenna,
  TbHandClick,
  TbWorld
} from "react-icons/all";
import CountUp from "react-countup";
import APICaller from "../backend/APICaller";
import {Swiper, SwiperSlide} from "swiper/react/swiper-react";
import {Autoplay} from "swiper";
import erste from "../assets/career/erste.png";
import cetelem from "../assets/career/cetelem.png";
import bb from "../assets/career/bb.png";
import otp from "../assets/career/otp.jpg";
import rf from "../assets/career/rf.png";
import city from "../assets/career/cit.png";

const PartnersSection = styled.div`
  width: 100%;
  margin: 12px 0;
  padding: 0 48px;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const PartnersHeadline = styled.h2`
  font-family: "Arial", sans-serif;
  color: #909090;
  font-size: 30pt;
  padding: 0;
  margin: 0 24px;
  width: 90%;
`;

const PartnerBlock = styled.div`
  flex-grow: 2;
  min-width: 200px;
  height: 200px;
  max-width: 200px;

  background-color: #f4f4f4;
  box-shadow: 0 0 6px -1px grey;

  transition: width 300ms ease-in-out, height 300ms ease-in-out, transform 300ms ease-in-out, box-shadow 300ms ease-in-out;

  &:hover {
    transform: scale(1.2);
  }

  & > img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`;

const NavLink = styled(NavLink2)`
  margin: 0 auto;
`;

const CustomDiv = styled.div`
  display: block;
  position: absolute;

  background-color: ${({background_color}) => background_color ? background_color : "rgba(255,255,255,0.1)"};
  top: ${({top}) => top ? top : "auto"};
  left: ${({left}) => left ? left : "auto"};
  right: ${({right}) => right ? right : "auto"};
  bottom: ${({bottom}) => bottom ? bottom : "auto"};

  width: ${({width}) => width ? width : "11px"};
  height: ${({height}) => height ? height : "11px"};

  ${({round}) => round === true && css`
    border-radius: 1000px;
  `};
`;

const Block1 = styled.div`
  min-height: 100vh;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;
  padding-top: 20vh;

  position: relative;
  overflow: hidden;

  @media screen and (max-width: 800px) {
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    padding: 120px 15px 0 15px;
  }
`;

const BlueBG = styled.div`
  width: 100%;
  height: fit-content;
  position: absolute;
  top: 0;
  left: 0;

  & > img {
    width: 100%;
  }

  & > img:nth-of-type(1) {
    display: block;
  }

  & > img:nth-of-type(2) {
    display: none;
  }

  @media screen and (min-width: 1200px) {
    top: -20%
  }

  @media screen and (min-width: 1400px) {
    top: -30%
  }

  @media screen and (min-width: 1600px) {
    top: -40%
  }

  @media screen and (min-width: 1800px) {
    top: -50%
  }

  @media screen and (min-width: 2000px) {
    top: -60%
  }

  @media screen and (max-width: 800px) {
    & > img:nth-of-type(1) {
      display: none;
    }

    & > img:nth-of-type(2) {
      display: block;
    }
  }
`;

const SVG = styled.div`
  position: absolute;
  top: ${({top}) => top ? top : "auto"};
  left: ${({left}) => left ? left : "auto"};
  right: ${({right}) => right ? right : "auto"};

  & > img {
    width: ${({width}) => width ? width : "auto"};
    height: ${({height}) => height ? height : "auto"};
  }
`;

const TeamPic = styled.div`
  width: 35vw;
  position: relative;
  padding: 5px;
  background-color: white;
  transform: rotate(15deg);
  border-radius: 20px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-left: 60px;

  & > div {
    position: absolute;
    width: 100%;
    height: 100%;
    border-radius: 20px;
    border: 1px solid rgba(208, 208, 208, 0.25);
    z-index: 1;
  }

  & > div:nth-of-type(1) {
    top: -10px;
    left: 10px;
  }

  & > div:nth-of-type(2) {
    top: -20px;
    left: 20px;
  }

  & > img {
    z-index: 2;
    border-radius: 10px;
    width: 100%;
    height: fit-content;
  }

  @media screen and (max-width: 800px) {
    margin-left: 30px;
    margin-top: 80px;
    width: 80%;
  }
`;

const HeadLine = styled.div`
  color: white;
  max-width: 40%;
  z-index: 2;
  margin: 0 30px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;

  & > h2 {
    text-align: left;
    font-size: 25pt;
    font-family: "Montserrat", sans-serif;
    font-weight: bold;
  }

  @media screen and (max-width: 800px) {
    width: 100%;
    margin: 0;
    max-width: 100%;

    & > h2 {
      font-size: 18pt;;
    }

    & > p {
      font-size: 10pt;
    }
  }
`;

const SmallText = styled.p`
  font-size: 12pt;
`;

const Block2More = styled.div`
  font-size: 11pt;
  font-weight: bold;
  font-family: "Montserrat", sans-serif;
  margin-top: 24px;

  a, a:visited, a:hover, a:active {
    text-decoration: none !important;
    color: rgb(255, 89, 49);
  }

  a:hover {
    color: rgb(198, 72, 41) !important;
  }
`;

const Block2 = styled.div`
  width: 100%;
  padding: 30px 120px;

  @media screen and (max-width: 800px) {
    padding: 30px 24px;
  }

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
`;

const Block3 = styled(Block1)`
  min-height: 80vh;
`;

const Block6 = styled(Block3)`
  min-height: 80vh;
`;

const Block4 = styled(Block2)`

`;

const Block7 = styled(Block2)`

`;

const Block8 = styled(Block3)`
  padding-top: 20px;
  min-height: 0;
`;

const Block2SelectedPaca = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 12px;
`;

const Block2ThreeBlocks = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  @media screen and (max-width: 800px) {
    flex-direction: column;
  }
`;

const Block2ThreeBlock = styled.div`
  width: 250px;
  height: 250px;
  cursor: pointer;

  border-radius: 10px;

  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 12px;
  border: 1px solid #efefef;

  margin: 0 15px;

  transition: background 300ms ease-in-out;

  &:hover {
    background: linear-gradient(-90deg, rgb(255, 69, 59), rgb(255, 129, 27));
    border: 1px solid transparent;

    h3, p {
      color: white !important;
    }
  }
`;

const Block2PacaText = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  flex-shrink: 0;
  flex-grow: 3;

  & > h3 {
    margin: 6px 0;
    font-weight: bold;
    text-align: center;
    font-family: "Montserrat", sans-serif;
    font-size: 14pt;
    transition: color 300ms ease-in-out;
  }

  & > p {
    color: #999999;
    margin: 0;
    text-align: center;
    font-family: "Montserrat", sans-serif;
    font-size: 9pt;
    line-height: 0.9rem;
    transition: color 300ms ease-in-out;
  }
`;

const Block2SmallPaca = styled.div`
  height: 30%;
  margin-bottom: 12px;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  position: relative;
  flex-shrink: 0;

  & > img {
    height: 150%;
  }

  & > svg {
    color: ${({color}) => color ? color : "white"};
    position: absolute;
    width: 30px;
    height: 30px;
    top: calc(50% - 22px);
    left: calc(50% - 15px);
  }
`;

const Block2Text1 = styled.div`
  width: 100%;
  text-align: ${({align}) => align ? align : "center"};

  font-family: Montserrat, sans-serif;
  font-size: 11pt;
  font-weight: bold;
  margin: 0 0 10px 0;
  color: rgb(255, 59, 69);
`;

const Block2Text2 = styled.div`
  width: 100%;
  text-align: center;

  font-family: Montserrat, sans-serif;
  font-size: 20pt;
  font-weight: bold;
  margin: 0 0 10px 0;
  color: #1a1b1e;
`;

const Block3About = styled.div`
  color: #0f1434;
  max-width: 40%;
  z-index: 2;
  margin: 0 30px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;

  & > h2 {
    text-align: left;
    font-size: 25pt;
    font-family: "Montserrat", sans-serif;
    font-weight: bold;
  }

  @media screen and (max-width: 800px) {
    width: 100%;
    margin: 0;
    max-width: 100%;

    & > h2 {
      font-size: 18pt;;
    }

    & > p {
      font-size: 10pt;
    }
  }
`;

const Block3TeamPic = styled(TeamPic)`

`;

const CounterWrapper = styled.div`
  border-radius: 10px;
  width: 100%;
  padding: 72px 24px;
  background: linear-gradient(90deg, rgb(0, 42, 164), rgb(0, 33, 90));
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  position: relative;

  @media screen and (max-width: 600px) {
    flex-wrap: wrap;
  }
`;

const CounterDiv = styled.div`
  height: 70px;
  width: 200px;
  padding: 0 24px;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;

  font-size: 24pt;
  font-weight: bold;
  font-family: "Montserrat", sans-serif;
  color: white;
  @media screen and (max-width: 600px) {
    width: 49%;
  }
`;

const CounterDesc = styled.div`
  font-size: 10pt;
  margin-top: 12px;
  font-weight: normal;
`;

const Block5 = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const WaveAfrica = styled.img`
  width: 100%;
  transform: rotate(${({angle}) => angle ? angle : "0"}deg);

  ${({angle}) => angle === 180 && css`
    position: relative;
    top: -1px;
  `};
`;

const Block5Content = styled.div`
  width: 100%;
  padding: 0 10%;
  background-color: #f6f9ff;
  min-height: 100px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;

  @media screen and (min-width: 1400px) {
    padding: 0 15%;
  }

  @media screen and (min-width: 1600px) {
    padding: 0 18%;
  }

  @media screen and (min-width: 1800px) {
    padding: 0 20%;
  }
`;

const RowOfCards = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
`;

const ColleagueInterrogation = styled(Block2ThreeBlock)`
  margin: 15px;
`;

const Block6Details = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
`;

const Block6Detail = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;

  margin: 12px 0;

  & > svg {
    margin: 0 12px;
    flex-shrink: 0;
    font-size: 24pt;
    color: #fc4445;
  }
`;

const Block6DetailText = styled.div`
  flex-grow: 2;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;

  & > h3 {
    font-family: Montserrat, sans-serif;
    font-size: 14pt;
    font-weight: bold;
    margin: 0 0 10px 0;
    color: #1a1b1e;
  }

  & > p {
    font-family: Montserrat, sans-serif;
    font-size: 10pt;
    font-weight: bold;
    margin: 0 0 10px 0;
    color: #999999;
  }
`;

const JobDiv = styled.div`
  user-select: none;
  width: 350px;
  height: 250px;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  position: relative;

  margin: 24px;
`;

const JobImg = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 10px;
`;

const JobDetails = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  width: 60%;
  height: fit-content;

  position: absolute;
  bottom: 15%;
  right: 0;
  color: white;
  font-size: 10pt;

  padding: 12px 6px 12px 24px;
  border-radius: 10px 0 0 10px;
  background: linear-gradient(-90deg, rgb(255, 69, 59), rgb(255, 129, 27));
  box-shadow: -3px 0 6px -1px rgba(36, 36, 36, 0.87);
  transition: box-shadow 150ms ease-in-out;

  cursor: pointer;

  &:hover {
    box-shadow: -3px 0 12px -1px rgba(23, 23, 23, 0.87);
  }

  &:active {
    box-shadow: -3px 0 2px -1px rgba(36, 36, 36, 0.87);
  }
`;

class Home extends Component {
  state: {
    selectedPaca: number,
    jobs: [],
    width: number,
  } = {
    selectedPaca: -1,
    jobs: [],
    width: window.innerWidth
  }

  resizeOccurred() {
    this.setState({
      width: window.innerWidth
    });
  }

  componentDidMount() {
    APICaller.getAllJobs(res => {
      let jobs = res;
      this.setState({jobs});
    });
    document.addEventListener('resize', () => this.resizeOccurred());
  }

  handleJobDetailsClick(e, job) {
    e.stopPropagation();

    if (!job)
      return;

    this.props.history.push({pathname: "/job/" + job.ID});
  }

  componentWillUnmount() {
    document.removeEventListener('resize', () => this.resizeOccurred());
  }

  getSlidesPerView() {
    let w = this.state.width;

    if (w >= 1500)
      return 5;
    else if (w < 1500 && w >= 1300)
      return 4;
    else if (w < 1300 && w >= 1000)
      return 3;
    else if (w < 1000 && w >= 800)
      return 2;
    else if (w < 800)
      return 1;
  }

  render() {
    return (
      <>
        <Block1>
          <>
            <BlueBG>
              <img src={blueBG} alt={"blueBG"}/>
              <img src={blue_bg_mobile} alt={"blueBG"}/>
            </BlueBG>
            <CustomDiv top={"0"} left={"10%"} width={"2px"} height={"80vh"}/>
            <CustomDiv top={"20%"} left={"calc(10% - 5px)"} width={"12px"} height={"12px"}
                       background_color={"rgba(255,255,255,0.45)"} round={true}/>
            <CustomDiv top={"0"} left={"35%"} width={"2px"} height={"80vh"}/>
            <CustomDiv top={"75%"} left={"calc(35% - 5px)"} width={"12px"} height={"12px"}
                       background_color={"rgba(255,255,255,0.45)"} round={true}/>
            <CustomDiv top={"0"} left={"65%"} width={"2px"} height={"60vh"}/>
            <CustomDiv top={"45%"} left={"calc(65% - 5px)"} width={"12px"} height={"12px"}
                       background_color={"rgba(255,255,255,0.45)"} round={true}/>
            <CustomDiv top={"0"} left={"90%"} width={"2px"} height={"40vh"}/>
            <CustomDiv top={"25%"} left={"calc(90% - 5px)"} width={"12px"} height={"12px"}
                       background_color={"rgba(255,255,255,0.45)"} round={true}/>
            <SVG top={"0"} left={"250px"} width={"200px"}>
              <img src={orangeGear} alt={"orangeGear"}/>
            </SVG>
            <SVG top={"0"} left={"0"} width={"150px"}>
              <img src={top_left_orange} alt={"topleftorange"}/>
            </SVG>
            <SVG top={"150px"} right={"0"} width={"120px"}>
              <img src={rightPaca} alt={"rightPaca"}/>
            </SVG>
          </>

          <HeadLine>
            <h2>Úgy érzed még nem találtad meg a hivatásod?</h2>
            <SmallText>
              Jelentkezz még ma és gyere be egy interjúra hozzánk
            </SmallText>
            <NavLink to={"/karrier"}>
              <div className={"button"}>
                Jelentkezem
              </div>
            </NavLink>
          </HeadLine>

          <TeamPic>
            <div/>
            <div/>
            <img src={team_selfie} alt={"csapat"}/>
          </TeamPic>

        </Block1>

        <Block2>
          <Block2Text1>Lehet, hogy pont rád várunk!</Block2Text1>
          <Block2Text2>
            Csatlakozz a csapatunkhoz<br/>
            MOST
          </Block2Text2>
          <Block2ThreeBlocks>
            <Block2ThreeBlock onClick={() => this.setState({selectedPaca: 1})}>
              <Block2SmallPaca>
                <img src={smallPacaBlue} alt={""}/>
                <TbWorld/>
              </Block2SmallPaca>
              <Block2PacaText>
                <h3>Fiatalos Csapat</h3>
                <p>Támogató barátságos környezet és csapat</p>
              </Block2PacaText>
            </Block2ThreeBlock>
            <Block2ThreeBlock onClick={() => this.setState({selectedPaca: 2})}>
              <Block2SmallPaca color={"rgb(255, 69, 59)"}>
                <img src={smallPacaWhite} alt={""}/>
                <HiOutlineDocumentText/>
              </Block2SmallPaca>
              <Block2PacaText>
                <h3>Luxus irodai környezet</h3>
                <p>Nagyon sokat törödünk azzal, hogy az irodánk inspiráló munkakörnyezetet adjon, ahová az emberek
                  szeretnek bejárni</p>
              </Block2PacaText>
            </Block2ThreeBlock>
            <Block2ThreeBlock onClick={() => this.setState({selectedPaca: 3})}>
              <Block2SmallPaca>
                <img src={smallPacaGreen} alt={""}/>
                <HiOutlineDocumentText/>
              </Block2SmallPaca>
              <Block2PacaText>
                <h3>Átlagon feletti kereset</h3>
                <p>
                  Minden mellett a munka fontos része az is, hogy mennyit keres a munkatárs. Azt valljuk, hogy a
                  Credithomenál a becsületes és szorgalmas munkatársnak minél több fejfájást spóroljunk meg az anyagiak
                  miatt. Így tudunk a legjobban a munkára fókuszálni.
                </p>
              </Block2PacaText>
            </Block2ThreeBlock>
          </Block2ThreeBlocks>
          {this.state.selectedPaca > 0 &&
          <Block2SelectedPaca>
            {this.state.selectedPaca === 1 &&
            <p>
              1 asdjnasdkln
            </p>
            }
            {this.state.selectedPaca === 2 &&
            <p>
              2 asdjnasdkln
            </p>
            }
            {this.state.selectedPaca === 3 &&
            <p>
              3 asdjnasdkln
            </p>
            }
          </Block2SelectedPaca>
          }
          <Block2More>
            Nem győztünk még meg? <NavLink to={"/kapcsolat"}>Olvass bővebben rólunk!</NavLink>
          </Block2More>
        </Block2>

        <Block3>
          <SVG top={"10%"} left={"0"}
               width={"100px"}
          >
            <img src={leftOrangePaca} alt={""}/>
          </SVG>
          <Block3About>
            <Block2Text1 align={"left"}>Credithome-ról</Block2Text1>
            <h2>Ami nálunk vár téged</h2>
            <SmallText>
              Folyamatos csapatépítő<br/>
              Folyamatos képzések<br/>
              Előrelépési lehetőségek<br/>
              Jófej vezetőség<br/>
            </SmallText>
            <NavLink to={"/karrier"}>
              <div className={"button"}>
                Jelentkezem
              </div>
            </NavLink>
          </Block3About>
          <Block3TeamPic>
            <img src={teamPaca2} alt={""}/>
          </Block3TeamPic>
        </Block3>

        <Block4>
          <CounterWrapper>
            <SVG top={"calc(50% - 100px)"} height={"200px"} left={"calc(50% - 100px)"} width={"auto"}>
              <img src={counterPaca} alt={""}/>
            </SVG>
            <CounterDiv>
              <CountUp end={567}
                       duration={4}
              />
              <CounterDesc>Kiosztott supershop kártya</CounterDesc>
            </CounterDiv>
            <CounterDiv>
              <CountUp end={86}
                       duration={4}
              />
              <CounterDesc>Csapatépítő buli</CounterDesc>
            </CounterDiv>
            <CounterDiv>
              <CountUp end={465}
                       duration={4}
              />
              <CounterDesc>Elégedett ügyfél</CounterDesc>
            </CounterDiv>
            <CounterDiv>
              <CountUp end={12}
                       duration={4}
              />
              <CounterDesc>év tapasztalat</CounterDesc>
            </CounterDiv>
          </CounterWrapper>
        </Block4>

        <Block5>
          <WaveAfrica angle={0} src={waving_flags_africa} alt={""}/>
          <Block5Content>
            <Block2Text1>Miért érdemes nálunk dolgozni?</Block2Text1>
            <Block2Text2>
              Nem mindegy, hogy hol töltöd életed egyharmadát. A Credithome több, mint egy
              munkahely.
            </Block2Text2>
            <RowOfCards>
              <ColleagueInterrogation>
                <Block2SmallPaca>
                  <img src={smallPacaBlue} alt={""}/>
                  <TbWorld/>
                </Block2SmallPaca>
                <Block2PacaText>
                  <h3>Barátok közt dolgozunk</h3>
                  <p>
                    Munkaidőin kívül is szeretünk együtt lógni, a Budapest Parkban már név szerint ismernek minket
                  </p>
                </Block2PacaText>
              </ColleagueInterrogation>
              <ColleagueInterrogation>
                <Block2SmallPaca>
                  <img src={smallPacaBlue} alt={""}/>
                  <TbWorld/>
                </Block2SmallPaca>
                <Block2PacaText>
                  <h3>Számíthatunk a vezetőinkre</h3>
                  <p>
                    Amikor a Covid alatt nem tudtunk dolgozni, akkor is folyamatosa anyagi támogatást kaptunk tőlük
                  </p>
                </Block2PacaText>
              </ColleagueInterrogation>
              <ColleagueInterrogation>
                <Block2SmallPaca>
                  <img src={smallPacaBlue} alt={""}/>
                  <TbWorld/>
                </Block2SmallPaca>
                <Block2PacaText>
                  <h3>Lehetőségünk van egyről a kettőre lépni</h3>
                  <p>
                    Hostessként kezdtem a pályafutásom 3 éve, azóta már manager pozícióban tevékenykedem. Annyira
                    szeretem, hogy nem is munkaként tekintek rá.
                  </p>
                </Block2PacaText>
              </ColleagueInterrogation>
              <ColleagueInterrogation>
                <Block2SmallPaca>
                  <img src={smallPacaBlue} alt={""}/>
                  <TbWorld/>
                </Block2SmallPaca>
                <Block2PacaText>
                  <h3>Itt fejlődhetek</h3>
                  <p>
                    Csupán csak érettségivel rendelkeztem, de azóta a Credithome-nál pénzügyi szakemberré váltam
                  </p>
                </Block2PacaText>
              </ColleagueInterrogation>
              {[4, 5].map((k, i) => {
                return (
                  <ColleagueInterrogation key={i}>
                    <Block2SmallPaca>
                      <img src={smallPacaBlue} alt={""}/>
                      <TbWorld/>
                    </Block2SmallPaca>
                    <Block2PacaText>
                      <h3>A{k + 1}</h3>
                      <p>
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut
                        labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud
                      </p>
                    </Block2PacaText>
                  </ColleagueInterrogation>
                )
              })}
            </RowOfCards>
          </Block5Content>
          <WaveAfrica angle={180} src={waving_flags_africa} alt={""}/>
        </Block5>

        <Block6>
          <Block3TeamPic>
            <img src={pacaOrange} alt={""}/>
          </Block3TeamPic>
          <Block3About>
            <Block2Text1 align={"left"}>Miért mi?</Block2Text1>
            <h2>Kollégáinkat folyamatosan képezzük!</h2>
            <Block6Details>
              <Block6Detail>
                <BiNetworkChart/>
                <Block6DetailText>
                  <h3>Személyes tanfolyamokkal</h3>
                  <p>Minden támogatást megadunk, ami a fejlődéshez kell</p>
                </Block6DetailText>
              </Block6Detail>
              <Block6Detail>
                <MdOutlineSettingsInputAntenna/>
                <Block6DetailText>
                  <h3>Oktatásokkal</h3>
                  <p>Minden támogatást megadunk, ami a fejlődéshez kell</p>
                </Block6DetailText>
              </Block6Detail>
              <Block6Detail>
                <TbHandClick/>
                <Block6DetailText>
                  <h3>Egyéni foglalkozásokkal</h3>
                  <p>Minden támogatást megadunk, ami a fejlődéshez kell</p>
                </Block6DetailText>
              </Block6Detail>
            </Block6Details>
            <NavLink to={"/karrier"}>
              <div className={"button"}>
                Jelentkezem
              </div>
            </NavLink>
          </Block3About>
        </Block6>

        <Block7>
          <SVG top={"10%"} right={"0"}
               width={"100px"}
          >
            <img src={rightPaca2} alt={""}/>
          </SVG>
          <Block2Text1>Nézz körbe állásportálunkon</Block2Text1>
          <Block2Text2>Nyitott pozícióink</Block2Text2>
          <RowOfCards>
            {this.state.jobs.map((job, i) => {
              return (
                <JobDiv key={i}>
                  <JobImg src={job.background_image} alt={job.title}/>
                  <JobDetails onClick={e => this.handleJobDetailsClick(e, job)}>
                    <b>Olvass tovább</b>
                    {job.title}
                  </JobDetails>
                </JobDiv>
              )
            })}
          </RowOfCards>
        </Block7>

        <Block8>
          <PartnersSection>
            <PartnersHeadline>Velük (is) fogsz együttdolgozni</PartnersHeadline>
            <Swiper
              style={{width: "100%", padding: "48px"}}
              grabCursor
              autoHeight={true}
              initialSlide={0}
              spaceBetween={24}
              slidesPerView={this.getSlidesPerView()}
              autoplay={{
                delay: 5000,
                disableOnInteraction: false,
              }}
              modules={[Autoplay]}
              loop={true}
            >
              <SwiperSlide>
                <PartnerBlock>
                  <img src={erste} alt={"erste"}/>
                </PartnerBlock>
              </SwiperSlide>
              <SwiperSlide>
                <PartnerBlock>
                  <img src={cetelem} alt={"cetelem"}/>
                </PartnerBlock>
              </SwiperSlide>
              <SwiperSlide>
                <PartnerBlock>
                  <img src={bb} alt={"bb"}/>
                </PartnerBlock>
              </SwiperSlide>
              <SwiperSlide>
                <PartnerBlock>
                  <img src={otp} alt={"otp"}/>
                </PartnerBlock>
              </SwiperSlide>
              <SwiperSlide>
                <PartnerBlock>
                  <img src={rf} alt={"rf"}/>
                </PartnerBlock>
              </SwiperSlide>
              <SwiperSlide>
                <PartnerBlock>
                  <img src={city} alt={"city"}/>
                </PartnerBlock>
              </SwiperSlide>
            </Swiper>
          </PartnersSection>
        </Block8>
      </>
    );
  }

  // render() {
  //   return (
  //     <div>
  //       <ParallaxProvider>
  //         <Parallax y={isMobile ? [-25, 25] : [-80, 80]}>
  //           <div className={"videoElement"}>
  //             <VideoPlayer/>
  //             <div className={"videoOverlay"}>
  //               <div className={"elerhetoseg"}>
  //                 <a href={"tel:+3617982945"}>
  //                   <Svg014PhoneCall width="20" height="20" viewBox={"0 0 40 40"}
  //                                    className={"footer_icon "} fill={"white"}/>
  //                   +36 1 798 2945
  //                 </a><br/>
  //
  //                 <a href={"mailto:service@credithome.hu"}>
  //                   <Svg015Contact width="20" height="20" viewBox={"0 0 50 50"}
  //                                  className={"footer_icon "} fill={"white"}/>
  //                   service@credithome.hu
  //                 </a><br/>
  //               </div>
  //
  //               <h1>Tervezze velünk a pénzügyeit!</h1>
  //               <br/>
  //               <NavLink2 to={"/karrier"}><h2>Csatlakozz csapatunkhoz!</h2></NavLink2>
  //               <ScrollDown/>
  //             </div>
  //           </div>
  //         </Parallax>
  //       </ParallaxProvider>
  //       <div className={"content"}>
  //         <CareerBlocks showButtons={true} long={false}/>
  //         <div className={"content_border"}/>
  //         <br/>
  //         <br/>
  //         <div className={"centeredRowWrapper"}>
  //           Várhatóan 2021 december végéig az általunk közvetített személyi hitelek esetében a THM 10,7% - 20,1%
  //           <br/>
  //           <br/>
  //         </div>
  //         <div className={"centeredRowWrapper"}>
  //           {/*<a className={"button"} href={"/kalkulator"}>Kölcsön kalkulátor</a>*/}
  //           <ContactButton/>
  //         </div>
  //         <div className={"home-icons frontpage-row"}>
  //           <div className={"card"} onClick={() => window.location = "/blog/11"}>
  //             <img className={"cardIcon"} alt={"adossagrendezes ikon"} src={icon_money}/>
  //             <div className={"cardContent"}>
  //               <div className={"cardTitle"}>
  //                 Adósságrendezés?
  //               </div>
  //               <div className={"text-icons"}>
  //                 Adósságrendezésben a toppon! <br/>
  //                 Vonja össze, vagy váltsa ki hiteleit<br/>
  //                 és takarítson meg akár több tízezer forintot!
  //               </div>
  //             </div>
  //           </div>
  //           <div className={"card"} onClick={() => window.location = "/blog/13"}>
  //             <img className={"cardIcon"} alt={"haz ikon"} src={icon_house}/>
  //             <div className={"cardContent"}>
  //               <div className={"cardTitle"}>
  //                 Lakásfelújítás?
  //               </div>
  //               <div className={"text-icons"}>
  //                 Kérje speciális ajánlatainkat lakásfelújítás ügyében!
  //               </div>
  //             </div>
  //           </div>
  //           <div className={"card"} onClick={() => window.location = "/blog/15"}>
  //             <img className={"cardIcon"} alt={"vakacio ikon"} src={icon_vacation}/>
  //             <div className={"cardContent"}>
  //               <div className={"cardTitle"}>
  //                 Nyaralás, új autó?
  //               </div>
  //               <div className={"text-icons"}>
  //                 Személyi kölcsöneink bármilyen célra felhasználhatók. Legyen ez autóvásárlás,
  //                 nyaralás vagy egy a hátsó kerti medence építés.
  //               </div>
  //             </div>
  //           </div>
  //           <div className={"card"} onClick={() => window.location = "/blog/17"}>
  //             <img className={"cardIcon"} alt={"auto ikon"} src={icon_car}/>
  //             <div className={"cardContent"}>
  //               <div className={"cardTitle"}>
  //                 Nagycsaládos autóvásárlás támogatása
  //               </div>
  //               <div className={"text-icons"}>
  //                 Kérje szakértőnk segítségét az önnek legkedvezőbb személyi hitelekkel kapcsolatban,
  //                 hogy megszerezhesse az álomautóját!
  //               </div>
  //             </div>
  //           </div>
  //           <div className={"card"} onClick={() => window.location = "/blog/21"}>
  //             <img className={"cardIcon"} alt={"auto ikon"} src={icon_baba}/>
  //             <div className={"cardContent"}>
  //               <div className={"cardTitle"}>
  //                 Babaváró hitel
  //               </div>
  //               <div className={"text-icons"}>
  //                 A Babaváró támogatást 2019-től igényelhetik a feltételeknek megfelelő fiatal párok,
  //                 melynek maximális összege 10 millió forint, futamideje pedig 20 év is lehet.
  //               </div>
  //             </div>
  //           </div>
  //           <div className={"card"} onClick={() => window.location = "/blog/23"}>
  //             <img className={"cardIcon"} alt={"auto ikon"} src={icon_csok}/>
  //             <div className={"cardContent"}>
  //               <div className={"cardTitle"}>
  //                 CSOK
  //               </div>
  //               <div className={"text-icons"}>
  //                 A CSOK egy vissza nem térítendő állami támogatás,ha teljesíti a
  //                 szerződésben foglaltakat. A támogatás legfeljebb 10 millió Ft lehet.
  //               </div>
  //             </div>
  //           </div>
  //           <div className={"card"} onClick={() => window.location = "/blog/25"}>
  //             <img className={"cardIcon"} alt={"auto ikon"} src={icon_forgoeszkoz}/>
  //             <div className={"cardContent"}>
  //               <div className={"cardTitle"}>
  //                 Forgóeszköz hitel
  //               </div>
  //               <div className={"text-icons"}>
  //                 A forgóeszköz hitel célja a vállalkozás rövid és/vagy hosszú távú
  //                 forgóeszközigényeinek finanszírozása.
  //               </div>
  //             </div>
  //           </div>
  //           {/*<div className={"card"} onClick={() => window.location = "/blog/27"}>*/}
  //           {/*    <img className={"cardIcon"} alt={"auto ikon"} src={icon_jelzalog}/>*/}
  //           {/*    <div className={"cardContent"}>*/}
  //           {/*        <div className={"cardTitle"}>*/}
  //           {/*            Jelzálog hitel*/}
  //           {/*        </div>*/}
  //           {/*        <div className={"text-icons"}>*/}
  //           {/*            A hitelért cserébe jelzálogot jegyeztetnek be egy általunk, vagy adóstársunk által*/}
  //           {/*            birtokolt nagy értékű tulajdonra, ami az esetek elsöprő többségében ingatlan lehet.*/}
  //           {/*        </div>*/}
  //           {/*    </div>*/}
  //           {/*</div>*/}
  //           <div className={"card"} onClick={() => window.location = "/blog/29"}>
  //             <img className={"cardIcon"} alt={"auto ikon"} src={icon_lombard}/>
  //             <div className={"cardContent"}>
  //               <div className={"cardTitle"}>
  //                 Lombard hitel
  //               </div>
  //               <div className={"text-icons"}>
  //                 A Lombard hitelt túlnyomórészt befektetési céllal, illetve más, személyes beruházási
  //                 okkal veszik igénybe ügyfeleink.
  //               </div>
  //             </div>
  //           </div>
  //           <div className={"card"} onClick={() => window.location = "/blog/31"}>
  //             <img className={"cardIcon"} alt={"auto ikon"} src={icon_tarsashaz}/>
  //             <div className={"cardContent"}>
  //               <div className={"cardTitle"}>
  //                 Társasházi hitelek
  //               </div>
  //               <div className={"text-icons"}>
  //                 Társasháza vagy lakásszövetkezethez tartozó házuk felújítását, korszerűsítését
  //                 megvalósíthatja a Társasházi hitelünkből.
  //               </div>
  //             </div>
  //           </div>
  //         </div>
  //
  //         <div className={"linebg"}>
  //           <h1>Ajánlott cikkek</h1>
  //           <BlogCarousel/>
  //         </div>
  //         <div className={"padding"}/>
  //         <Feedbacks/>
  //       </div>
  //     </div>
  //   )
  // }
}

export default withRouter(Home);
